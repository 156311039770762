import React from "react";
import Footer from "../../components/footer/Footer";
import { FaArrowLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import Chip from "../../components/chips/chip/Chip";

const chipConfig = {
  weeklytarget: {
    color: "75,173,187",
    text: "Weekly Target",
  },
  bonuschallenge: {
    color: "90,204,0",
    text: "Bonus Challenge",
  },
};

const InternalLayout2 = ({
  children,
  handleBack,
  count,
  maxCount,
  showFooter,
}) => {
  const location = useLocation();

  return (
    <div className="flex justify-center">
      <div className="internal-layout flex flex-col h-screen">
        <div className="header flex items-center px-6 justify-between">
          <button
            onClick={handleBack}
            className="back-btn flex items-center gap-2"
          >
            <FaArrowLeft />
          </button>
          {chipConfig[location.pathname.replace("/", "").replace("-", "")] && (
            <Chip
              {...chipConfig[
                location.pathname.replace("/", "").replace("-", "")
              ]}
            />
          )}
          {maxCount && (
            <div className="header-count-box flex items-center">
              {count} / {maxCount}
            </div>
          )}
        </div>
        <main className="main grow flex flex-col">
          <div className="wrapper p-6 grow flex flex-col">{children}</div>
        </main>
        {showFooter && <Footer />}
      </div>
    </div>
  );
};

export default InternalLayout2;
