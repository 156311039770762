import React, { useCallback, useEffect, useState } from "react";
import { Copy, Coin } from "../../assets/icons";
import PageTitle from "../../components/pageTitle/PageTitle";
import Chip from "../../components/chips/chip/Chip";
import TokenChip from "../../components/chips/tokenChip/TokenChip";
import Timer from "../../components/timer/Timer";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { services } from "../../services";
import Loader from "../../components/loader/Loader";
import GetEndTime from "../../components/getEndTime/GetEndTime";
import { useNavigate } from "react-router-dom";
import CongratulationModal from "../../components/congratulationModal/CongratulationModal";
import { setUser } from "../../store/slices/userSlice";

const getCurrentTime = () => {
  return Math.ceil(new Date().getTime() / 1000);
};

const Challenges = () => {
  const [loading, setLoading] = useState(false);
  const [challengesDetails, setChallengesDetails] = useState(null);
  const [isCongratulationsModalOpen, setIsCongratulationModalOpen] =
    useState(false);
  const [rewardString, setRewardString] = useState("");
  const [claimedRewardCount, setClaimedRewardCount] = useState(0);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const traction_invite_code = useSelector(
    (state) => state.user.traction_invite_code
  );

  const getChallenges = useCallback(async () => {
    setLoading(true);
    try {
      const response = await services.getChallenges();
      setChallengesDetails(response.data.result.details);
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.result?.message) {
        toast.error(error.response.data.result.message);
      } else {
        toast.error(error.message);
      }
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    getChallenges();
  }, [getChallenges]);

  const handleCongratulationsModalClose = async () => {
    setIsCongratulationModalOpen(false);
    await getChallenges();
    setClaimedRewardCount(0);
    setRewardString("");
  };

  const handleCongratulationsModalSubmit = async () => {
    setLoading(true);
    setIsCongratulationModalOpen(false);
    try {
      const response = await services.submitClaim({
        data: {
          claim_id: challengesDetails?.pendingClaimIds[claimedRewardCount],
        },
      });
      setClaimedRewardCount((prev) => prev + 1);
      setIsCongratulationModalOpen(true);
      const user = await services.getUser();
      dispatch(setUser({ ...user.data.result.userData }));
      setRewardString(response.data.result.reward);
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.result?.message) {
        toast.error(error.response.data.result.message);
      } else {
        toast.error(error.message);
      }
    }
    setLoading(false);
  };

  return (
    <>
      <div className="challenges-page flex flex-col">
        <PageTitle title="Challenge" />
        <div className="wrapper flex flex-col gap-6 pt-6">
          <div className="ref-link-box flex ">
            <div className="left flex items-center capitalize">
              {t("referralLink")}
            </div>
            <div className="right grow flex items-center justify-between overflow-hidden">
              <span className="">
                {`${window.location.host}/onboarding?inviteCode=${traction_invite_code}`.slice(
                  0,
                  23
                )}
                ...
              </span>
              <button
                onClick={() => {
                  navigator.clipboard.writeText(
                    `https://${window.location.host}/onboarding?inviteCode=${traction_invite_code}`
                  );
                  toast.success(t("referalLinkCopied"));
                }}
                className=""
              >
                <Copy />
              </button>
            </div>
          </div>

          {challengesDetails && (
            <>
              {challengesDetails?.pendingClaimIds.length > 0 && (
                <button
                  onClick={handleCongratulationsModalSubmit}
                  className="claim-card my-2"
                >
                  <div className="front-box box flex flex-row flex-wrap gap-5 justify-between items-center">
                    <div className="coins flex flex-row gap-2 bg-black justify-between items-center px-5 py-2">
                      <Coin />
                      <h4>{challengesDetails.pendingClaimAmt} xCAPx</h4>
                    </div>
                    <div className="multiplier">
                      {challengesDetails?.pendingClaimIds.length > 1 && (
                        <h1
                          className={`${
                            challengesDetails?.pendingClaimIds.length > 9
                              ? "size-md"
                              : challengesDetails?.pendingClaimIds.length > 99
                              ? "size-small"
                              : "size-big"
                          }`}
                        >
                          {challengesDetails?.pendingClaimIds.length}x
                        </h1>
                      )}
                    </div>
                    <div className="claim-heading">
                      <h1 className="text-left">{t("claimYour")}</h1>
                      <h1 className="text-left">{t("challengeReward")}</h1>
                    </div>
                  </div>
                  <div className="background-box box"></div>
                </button>
              )}

              <div
                className={`paper flex flex-col gap-3 ${
                  challengesDetails.normalChallenge["LVL-1"].locked
                    ? "locked-challenge"
                    : ""
                }`}
              >
                <div className="flex gap-2">
                  <Chip
                    color="90,204,0"
                    className="self-start"
                    text="Challenge - Lvl 1"
                  />
                  <TokenChip text={"3 xCapx"} />
                </div>
                <p className="banner-text w-8/12">{t("challengeLevel1Text")}</p>
                <Timer
                  leftText={t("invited")}
                  type={
                    challengesDetails.normalChallenge["LVL-1"].locked
                      ? "locked"
                      : ""
                  }
                  rightText={`${challengesDetails.normalChallenge["LVL-1"].questData.invites} / ${challengesDetails.normalChallenge["LVL-1"].questData.invites_required}`}
                  color="rgba(90, 204, 0, 0.70)"
                  borderColor="#B8FF81"
                />
              </div>
              <div
                className={`paper flex flex-col gap-3 ${
                  challengesDetails.normalChallenge["LVL-2"].locked
                    ? "locked-challenge"
                    : ""
                }`}
              >
                <div className="flex gap-2">
                  <Chip
                    color="90,204,0"
                    className="self-start"
                    text="Challenge - Lvl 2"
                  />
                  <TokenChip text={"15 xCapx"} />
                </div>
                <p className="banner-text w-8/12">{t("challengeLevel2Text")}</p>
                <Timer
                  leftText={t("invited")}
                  type={
                    challengesDetails.normalChallenge["LVL-2"].locked
                      ? "locked"
                      : ""
                  }
                  rightText={`${challengesDetails.normalChallenge["LVL-2"].questData.invites} / ${challengesDetails.normalChallenge["LVL-2"].questData.invites_required}`}
                  color="rgba(90, 204, 0, 0.70)"
                  borderColor="#B8FF81"
                />
              </div>
              <div
                className={`paper flex flex-col gap-3 ${
                  challengesDetails.normalChallenge["LVL-3"].locked
                    ? "locked-challenge"
                    : ""
                }`}
              >
                <div className="flex gap-2">
                  <Chip
                    color="90,204,0"
                    className="self-start"
                    text="Challenge - Lvl 3"
                  />
                  <TokenChip text={"40 xCapx"} />
                </div>
                <p className="banner-text w-8/12">{t("challengeLevel3Text")}</p>
                <Timer
                  leftText={t("invited")}
                  type={
                    challengesDetails.normalChallenge["LVL-3"].locked
                      ? "locked"
                      : ""
                  }
                  rightText={`${challengesDetails.normalChallenge["LVL-3"].questData.invites} / ${challengesDetails.normalChallenge["LVL-3"].questData.invites_required}`}
                  color="rgba(90, 204, 0, 0.70)"
                  borderColor="#B8FF81"
                />
              </div>
              <button
                className="paper flex flex-col gap-3"
                onClick={() => {
                  if (
                    challengesDetails.bonusChallenge.startTime <
                    getCurrentTime()
                  ) {
                    navigate("/bonus-challenge");
                  }
                }}
              >
                <div className="flex gap-2">
                  <Chip
                    color="90,204,0"
                    className="self-start"
                    text="Bonus Challenge"
                  />
                  <TokenChip text={"30 xCapx"} />
                </div>
                <p className="banner-text w-8/12 text-left">
                  {t("bonusChallengeText")}
                </p>
                <Timer
                  leftText={
                    challengesDetails.bonusChallenge.startTime <
                    getCurrentTime()
                      ? t("endsIn")
                      : t("unlocksIn")
                  }
                  type="timer"
                  rightText={
                    challengesDetails.bonusChallenge.startTime <
                    getCurrentTime() ? (
                      <GetEndTime
                        endTime={challengesDetails.bonusChallenge.endTime}
                      />
                    ) : (
                      <GetEndTime
                        endTime={challengesDetails.bonusChallenge.startTime}
                      />
                    )
                  }
                  color="rgba(70, 150, 8)"
                  borderColor="#B8FF81"
                />
              </button>
            </>
          )}
        </div>
      </div>
      <CongratulationModal
        open={isCongratulationsModalOpen}
        onClose={handleCongratulationsModalClose}
        maxCount={challengesDetails?.pendingClaimIds.length}
        count={claimedRewardCount}
        handleSubmit={
          challengesDetails?.pendingClaimIds.length > claimedRewardCount
            ? handleCongratulationsModalSubmit
            : handleCongratulationsModalClose
        }
        btnText={
          challengesDetails?.pendingClaimIds.length > claimedRewardCount
            ? t("claimNextChallenge")
            : t("backToChallenge")
        }
        btnVariant={
          challengesDetails?.pendingClaimIds.length > claimedRewardCount
            ? ""
            : "outlined"
        }
        text={
          <>
            {t("youJustEarned")}
            <br />
            {rewardString}
          </>
        }
      />
      {loading && <Loader />}
    </>
  );
};

export default Challenges;
