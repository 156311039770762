import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthReady: false,
  isLoggedIn: false,
  user: null,
  isUserProfileSet: false,
  emailVerified: null,
  tokenDetails: null,
  isAddressGenerated: null,
  web3wallet: null,
  web3SwWallet: null,
  provider: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.isAuthReady = action.payload.isAuthReady;
      state.isLoggedIn = action.payload.isLoggedIn;
      state.emailVerified = action.payload.emailVerified;
      state.user = action.payload.user;
      state.isUserProfileSet = action.payload.isUserProfileSet;
    },
    setEmailVerified: (state, action) => {
      state.emailVerified = action.payload;
    },
    setIsUserProfileSet: (state, action) => {
      state.isUserProfileSet = action.payload;
    },
    resetAuth: (state) => {
      state.isAuthReady = true;
      state.isLoggedIn = false;
      state.user = null;
      state.isUserProfileSet = false;
      state.emailVerified = null;
      state.tokenDetails = null;
      state.isAddressGenerated = null;
      state.web3wallet = null;
      state.web3SwWallet = null;
      state.provider = null;
    },
  },
});

export const { setAuth, setEmailVerified, resetAuth, setIsUserProfileSet } =
  authSlice.actions;

export default authSlice.reducer;
