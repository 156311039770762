import React, { useCallback, useEffect, useRef, useState } from "react";
import PageTitle from "../../components/pageTitle/PageTitle";
import Button from "../../components/button/Button";
import { Copy, Discord, Gmail, Twitter, Whatsapp } from "../../assets/icons";
import { BiEditAlt } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import { services } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../store/slices/userSlice";
import Loader from "../../components/loader/Loader";
import { config } from "../../config";
import useQueryParams from "../../hooks/useQueryParams";
import { useFireBaseAuth } from "../../hooks/useFirebaseAuth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase/firebase";
import Tooltip from "../../components/tooltip/Tooltip";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useFirebaseImageUpload } from "../../hooks/useFirebaseImageUpload";

const FILE_SIZE = 5000000;
const SUPPORTED_FORMATS = ["image/jpeg", "image/png"];
const nameValidatore = Yup.string()
  .required("nameIsRequired")
  .max(40, "nameTooLong")
  .matches(/^[A-Za-z\s]+$/, "noNumberInName");
const ageValidatore = Yup.number()
  .required("ageIsRequired")
  .min(18, "must18Years")
  .max(99, "validAge");
const imageValidatore = Yup.mixed()
  .test("fileSize", "fileTooLarge", (value) => {
    if (!value) {
      return true;
    } else {
      return value && value.size <= FILE_SIZE;
    }
  })
  .test("fileFormat", "unsupportedFormat", (value) => {
    if (!value) {
      return true;
    } else {
      return value && SUPPORTED_FORMATS.includes(value.type);
    }
  });

const Profile = () => {
  const [isEditEnable, setIsEditEnable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imgPrev, setImgPrev] = useState(null);

  const getQueryParam = useQueryParams();
  const navigate = useNavigate();
  const { linkWithSocail, getLinkResult } = useFireBaseAuth();
  const { t } = useTranslation();
  const imageInputRef = useRef();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { uploadImageToCloud } = useFirebaseImageUpload();

  const formik = useFormik({
    initialValues: {
      imageFile: "",
      name: user?.name,
      age: user?.age,
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (
          values.name === user?.name &&
          values.age == user?.age &&
          !values.imageFile
        ) {
          setIsEditEnable(false);
        } else {
          nameValidatore.validateSync(values.name);
          ageValidatore.validateSync(values.age);
          let apiData = {
            name: values.name,
            age: values.age.toString(),
          };
          if (values.imageFile) {
            const image_url = await uploadImageToCloud(
              `images/profileImage/${auth.currentUser?.uid}`,
              values.imageFile
            );
            apiData.image_url = image_url;
          }
          await services.updateUserDetails({
            data: apiData,
          });
          await getUser();
        }
        setIsEditEnable(false);
        setImgPrev(null);
      } catch (error) {
        console.log(error);
        if (error?.response?.data?.result?.message) {
          toast.error(error.response.data.result.message);
        } else {
          toast.error(t(error.message));
        }
      }

      setLoading(false);
    },
  });

  const getUser = useCallback(async () => {
    setLoading(true);
    try {
      const user = await services.getUser();
      const isTwitterLinked =
        auth.currentuser?.providerData.filter((el) => {
          return el.providerId.includes("twitter");
        }).length > 0;
      if (isTwitterLinked && !user?.data.result.userData.socials.twitter_id) {
        await services.linkTwitter();
      }
      dispatch(setUser({ ...user?.data.result.userData }));
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.result?.message) {
        toast.error(error.response.data.result.message);
      } else {
        toast.error(error.message);
      }
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const linkResult = await getLinkResult();
        const code = getQueryParam.get("code");
        if (code) {
          await services.linkDiscord(code);
          navigate(`/profile`);
        } else if (linkResult && linkResult.providerId.includes("twitter")) {
          await services.linkTwitter();
        }

        await getUser();
      } catch (error) {
        console.log(error);
        if (error?.response?.data?.result?.message) {
          toast.error(error.response.data.result.message);
        } else {
          toast.error(error.message);
        }
      }
    })();
  }, [getUser]);

  const handleEditButton = () => {
    if (isEditEnable) {
      formik.handleSubmit();
    } else {
      formik.setFieldValue("name", user?.name);
      formik.setFieldValue("age", user?.age);
      setIsEditEnable((prev) => !prev);
    }
  };

  const handleSocialLink = (method) => {
    if (method === "discord") {
      window.location.href = `${config.API_URL}/discord/link`;
    } else if (method === "twitter") {
      linkWithSocail(method);
    }
  };

  const handleFileChange = async (e) => {
    try {
      const file = e.target.files[0];
      imageValidatore.validateSync(file);
      const url = URL.createObjectURL(file);
      setImgPrev(url);
      formik.setFieldValue("imageFile", file);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  return (
    <>
      <div className="profile-page flex flex-col grow">
        <PageTitle title="Profile" />
        <div className="content-wrapper flex flex-col grow gap-6 p-6">
          <div className="profile flex flex-col gap-3">
            <Button
              onClick={handleEditButton}
              className="self-end edit-btn -mb-10"
              variant="outlined"
            >
              {isEditEnable ? <span>SAVE</span> : <BiEditAlt />}
            </Button>
            <div className="profile-img-box relative rounded-full w-9/12 self-center overflow-hidden flex items-center justify-center">
              <input
                className="hidden"
                type="file"
                name="imageFile"
                onChange={handleFileChange}
                onBlur={formik.handleBlur}
                ref={imageInputRef}
                accept="image/png, image/jpeg"
              />
              {user?.image_url || imgPrev ? (
                <img
                  className="h-full w-full object-cover"
                  src={imgPrev ? imgPrev : user?.image_url}
                  alt="profile"
                />
              ) : (
                user?.name.slice(0, 1)
              )}
              {isEditEnable && (
                <div
                  onClick={() => {
                    if (isEditEnable) {
                      imageInputRef.current.click();
                    }
                  }}
                  className="upload-icon absolute inset-0 h-full w-full flex items-center justify-center"
                >
                  <AiOutlineCloudUpload />
                </div>
              )}
            </div>
            <div className="flex flex-col ">
              {isEditEnable ? (
                <div className="flex justify-center gap-2">
                  <input
                    className="input name-input"
                    type="text"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="name"
                  />
                  <input
                    className="input age-input placeholder:test-[12px]"
                    type="number"
                    value={formik.values.age}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Age"
                    name="age"
                  />
                </div>
              ) : (
                <div className="flex justify-center gap-2">
                  <p className="text-center name">{user?.name}</p>
                  {user?.age && <p className="age">{user?.age} yr</p>}
                </div>
              )}

              <p className="username text-center">@{user?.username}</p>
            </div>
            <div className="social-btn-grp flex items-center gap-2 justify-center">
              {user?.phone && (
                <Tooltip message={user?.phone}>
                  <button
                    className={isEditEnable && user?.phone ? "opacity-60" : ""}
                  >
                    <Whatsapp />
                  </button>
                </Tooltip>
              )}
              {!user?.socials?.twitter_id ? (
                <button
                  className={
                    !isEditEnable && !user?.socials?.twitter_id
                      ? "opacity-60"
                      : ""
                  }
                  onClick={() => {
                    if (isEditEnable && !user?.socials?.twitter_id) {
                      handleSocialLink("twitter");
                    }
                  }}
                >
                  <Twitter />
                </button>
              ) : (
                <Tooltip message={user?.socials?.twitter_username}>
                  <button
                    className={
                      isEditEnable && user?.socials?.twitter_id
                        ? "opacity-60"
                        : ""
                    }
                  >
                    <Twitter />
                  </button>
                </Tooltip>
              )}
              {!user?.socials?.discord_id ? (
                <button
                  className={
                    !isEditEnable && !user?.socials?.discord_id
                      ? "opacity-60"
                      : ""
                  }
                  onClick={() => {
                    if (isEditEnable && !user?.socials?.discord_id) {
                      handleSocialLink("discord");
                    }
                  }}
                >
                  <Discord />
                </button>
              ) : (
                <Tooltip message={user?.socials?.discord_username}>
                  <button
                    className={
                      isEditEnable && user?.socials?.discord_id
                        ? "opacity-60"
                        : ""
                    }
                  >
                    <Discord />
                  </button>
                </Tooltip>
              )}
              {user?.email && (
                <Tooltip message={user?.email}>
                  <button
                    className={isEditEnable && user?.email ? "opacity-60" : ""}
                  >
                    <Gmail />
                  </button>
                </Tooltip>
              )}
            </div>
          </div>
          <div
            className={`statistics flex flex-col gap-4 ${
              isEditEnable ? "opacity-60" : ""
            }`}
          >
            <div className="sta-box flex flex-col gap-2">
              <h5 className="heading">{t("referralLink")}</h5>
              <div className="flex gap-2">
                <p className="text grow">
                  {`${window.location.host}/onboarding?inviteCode=${user?.traction_invite_code}`.slice(
                    0,
                    23
                  )}
                  ...
                </p>
                <button
                  className="copy-btn"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `https://${window.location.host}/onboarding?inviteCode=${user?.traction_invite_code}`
                    );
                    toast.success(t("referalLinkCopied"));
                  }}
                >
                  <Copy />
                </button>
              </div>
            </div>
            <div className="sta-box flex flex-col gap-2">
              <h5 className="heading">{t("friendsInvitedUsingReferalLink")}</h5>
              <div className="flex gap-2">
                <p className="text grow">{user?.traction_invites}</p>
              </div>
            </div>
            <div className="sta-box flex flex-col gap-2">
              <h5 className="heading">{t("xCapxEarnedUsingReferralLink")}</h5>
              <div className="flex gap-2">
                <p className="text grow">
                  {user?.traction_total_rewards
                    ? user?.traction_total_rewards
                    : 0}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default Profile;
