import { Transition } from "@headlessui/react";
import React from "react";
import { toast, Toaster, ToastIcon, useToaster } from "react-hot-toast";
import { CheckIcon, ErrorIcon } from "../../assets/icons";
import { MdClose } from "react-icons/md";

const toasterSettings = {
  position: "top-right",
  toastOptions: {
    duration: 8000,
  },
};

const Notification = () => (
  <Toaster {...toasterSettings}>
    {(t) => {
      return (
        <Transition
          appear
          show={t.visible}
          className="transform custom-toaster flex items-center gap-3"
          enter="transition-all duration-150"
          enterFrom="opacity-0 scale-50"
          enterTo="opacity-100 scale-100"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-75"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {t.type === "success" && <CheckIcon className="icon" />}
          {t.type === "error" && <ErrorIcon className="icon" />}

          {/* */}
          <p className="text">{t.message}</p>
          <button
            onClick={(e) => {
              e.stopPropagation();
              toast.dismiss(t.id);
            }}
            className="self-start"
          >
            <MdClose />
          </button>
        </Transition>
      );
    }}
  </Toaster>
);

export default Notification;
