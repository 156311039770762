import React, { useState } from "react";
import Input from "../../../components/Input/Input";
import Button from "../../../components/button/Button";
import { toast } from "react-hot-toast";
import { Capx } from "../../../assets/icons";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useFireBaseAuth } from "../../../hooks/useFirebaseAuth";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loader from "../../../components/loader/Loader";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const mediumScreen = useMediaQuery("md");
  const { forgotPassword } = useFireBaseAuth();

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required("emailIsRequired")
        .matches(/^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/, "invalidEmailAddress"),
    }),
    validateOnChange: true,
    validateOnMount: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await forgotPassword(values.email);
        toast.success(t("passwordResetLinkSentSuccessfully"));
      } catch (error) {
        toast.error(error.message);
      }
      setLoading(false);
    },
  });

  return (
    <>
      <div className="forgot-password-page page flex flex-col items-center">
        <div className=" wrappper min-h-screen flex flex-col items-center gap-10 md:gap-8 md:p-3 md:pt-32 pt-6 px-8">
          <Capx />
          <div className="flex flex-col gap-20 grow">
            <div className="flex flex-col gap-3">
              <h3 className="cus-h cus-h-2 text-center">
                {t("forgotPassword")}
              </h3>
              <p className="p-text px-12">{t("NothingtoWorryLetsGetYouIn")}</p>
            </div>

            <div className="flex flex-col gap-4 self-stretch grow">
              {mediumScreen && <div className="grow" />}
              <div className="flex flex-col">
                <Input
                  label={t("enterYourEmail")}
                  placeholder={t("enterYourEmail")}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="email"
                  error={formik.errors.email && formik.touched.email}
                />
                {formik.errors.email && formik.touched.email ? (
                  <p className="helper-text mt-2">{t(formik.errors.email)}</p>
                ) : null}
              </div>
              {mediumScreen && (
                <>
                  <div className="grow" />
                  <div className="grow" />
                </>
              )}

              <Button
                disabled={!formik.isValid || !formik.dirty}
                className="sub-btn mb-10"
                onClick={formik.handleSubmit}
                bRadius={"md"}
              >
                {t("sendPasswordResetLink")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default ForgotPassword;
