export const config = {
  // v3
  APIKey: process.env.REACT_APP_FIREBASE_API_KEY,
  AuthDomain: process.env.REACT_APP_FIREBASE_API_AUTHDOMAIN,
  storageBucket: process.env.REACT_APP_FIREBASE_API_STORAGEBUCKET,
  ProjectId: process.env.REACT_APP_FIREBASE_API_PROJECTID,
  MessagingSenderId: process.env.REACT_APP_FIREBASE_API_MESSAGINGSENDERID,
  AppId: process.env.REACT_APP_FIREBASE_API_APPID,

  // v2
  APIKeyV2: process.env.REACT_APP_FIREBASE_V2_API_KEY,
  AuthDomainV2: process.env.REACT_APP_FIREBASE_V2_API_AUTHDOMAIN,
  storageBucketV2: process.env.REACT_APP_FIREBASE_V2_API_STORAGEBUCKET,
  ProjectIdV2: process.env.REACT_APP_FIREBASE_V2_API_PROJECTID,
  MessagingSenderIdV2: process.env.REACT_APP_FIREBASE_V2_API_MESSAGINGSENDERID,
  AppIdV2: process.env.REACT_APP_FIREBASE_V2_API_APPID,
  API_URL: process.env.REACT_APP_API_ENDPOINT,
  ArcanaClientId: process.env.REACT_APP_ARCANA_CLIENT_ID,
  WEB3AUTH_CLIENT_ID: process.env.REACT_APP_WEB3AUTH_CLIENT_ID,
  WEB3AUTH_NETWORK: process.env.REACT_APP_WEB3AUTH_NETWORK,
  WEB3AUTH_VERIFIER: process.env.REACT_APP_WEB3AUTH_VERIFIER,
  WEB3AUTH_CHAIN_ID: process.env.REACT_APP_WEB3AUTH_CHAIN_ID,
  WEB3AUTH_RPC_TARGET: process.env.REACT_APP_WEB3AUTH_RPC_TARGET,
  WEB3AUTH_DISPLAY_NAME: process.env.REACT_APP_WEB3AUTH_DISPLAY_NAME,
  WEB3AUTH_BLOCK_EXPLORER: process.env.REACT_APP_WEB3AUTH_BLOCK_EXPLORER,
  WEB3AUTH_TICKER: process.env.REACT_APP_WEB3AUTH_TICKER,
  WEB3AUTH_TICKER_NAME: process.env.REACT_APP_WEB3AUTH_TICKER_NAME,
};
