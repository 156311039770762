import {
  signInWithEmailAndPassword,
  signInWithCustomToken,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  applyActionCode,
  signOut,
  GoogleAuthProvider,
  TwitterAuthProvider,
  linkWithRedirect,
  getRedirectResult,
  sendPasswordResetEmail,
  confirmPasswordReset,
} from "firebase/auth";
import { auth } from "../firebase/firebase";
import { toast } from "react-hot-toast";

export const useFireBaseAuth = () => {
  const signInUser = async (email, password) => {
    const response = await signInWithEmailAndPassword(auth, email, password);
    if (!response) {
      throw new Error("Could not complete signin");
    }
    return response;
  };

  const signupUsingEmail = async (email, password) => {
    const response = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    if (!response) {
      throw new Error("Could not complete signup");
    }

    return response;
  };

  const customSignin = async (token) => {
    const response = await signInWithCustomToken(auth, token);
    if (!response) {
      throw new Error("Could not complete signin");
    }

    return response;
  };

  const verifyEmail = async () => {
    return await sendEmailVerification(auth.currentUser);
  };

  const verifyEmailCall = async (actionCode) => {
    return await applyActionCode(auth, actionCode);
  };

  const signOutUser = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  const linkWithSocail = async (method) => {
    if (method) {
      let provider = null;

      switch (method.toUpperCase()) {
        case "GOOGLE": {
          provider = new GoogleAuthProvider();
          break;
        }
        case "TWITTER": {
          provider = new TwitterAuthProvider();
          break;
        }
        default: {
          provider = new GoogleAuthProvider();
        }
      }

      try {
        await linkWithRedirect(auth.currentUser, provider);
      } catch (error) {
        console.log(error);
      }
    } else {
      throw new Error("Provider not passed");
    }
  };

  const getLinkResult = () => {
    return getRedirectResult(auth);
  };

  const forgotPassword = async (email) => {
    await sendPasswordResetEmail(auth, email);
  };

  const resetPassword = async (oobCode, newPassword) => {
    await confirmPasswordReset(auth, oobCode, newPassword);
  };

  return {
    customSignin,
    signInUser,
    signupUsingEmail,
    verifyEmail,
    verifyEmailCall,
    signOutUser,
    getLinkResult,
    linkWithSocail,
    forgotPassword,
    resetPassword,
  };
};
