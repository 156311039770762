import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  CapxTick,
  Discord,
  Telegram,
  Translation,
  Web,
} from "../../assets/icons";
import Chip from "../../components/chips/chip/Chip";
import Timer from "../../components/timer/Timer";
import TokenChip from "../../components/chips/tokenChip/TokenChip";
import { Menu, Transition } from "@headlessui/react";
import { BsCaretDownFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ConfirmationDialog from "./components/confirmationDialog/ConfirmationDialog";
import { services } from "../../services";
import { toast } from "react-hot-toast";
import Loader from "../../components/loader/Loader";
import GetEndTime from "../../components/getEndTime/GetEndTime";
import { setUser } from "../../store/slices/userSlice";
import { useDispatch } from "react-redux";

const challengeObj = {
  Bonus: {
    reward_string: "30 xCAPX",
    chipTitle: "Bonus Challenge",
    text: "bonusChallengeText",
  },
  "LVL-1": {
    reward_string: "3 xCAPX",
    chipTitle: "Challenge - Lvl 1",
    text: "challengeLevel1Text",
  },
  "LVL-2": {
    reward_string: "12 xCAPX",
    chipTitle: "Challenge - Lvl 2",
    text: "challengeLevel2Text",
  },
  "LVL-3": {
    reward_string: "40 xCAPX",
    chipTitle: "Challenge - Lvl 3",
    text: "challengeLevel3Text",
  },
};

const getCurrentTime = () => {
  return Math.ceil(new Date().getTime() / 1000);
};

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [dashboardDetails, setdashboardDetails] = useState(null);
  const [showTimer, setShowTimer] = useState({ mine: false, arcade: false });

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const getDashboard = useCallback(async () => {
    setLoading(true);
    try {
      const response = await services.getDashboard();
      setdashboardDetails(response.data.result.dashboardDetails);
      const currentTime = getCurrentTime();
      const stateData = {
        mine:
          response.data.result.dashboardDetails.mineQuestData.unlock_at >
          currentTime,
        arcade:
          response.data.result.dashboardDetails.spinWheelQuestData.unlock_at >
          currentTime,
      };

      setShowTimer(stateData);
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.result?.message) {
        toast.error(error.response.data.result.message);
      } else {
        toast.error(error.message);
      }
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      getDashboard();
      try {
        const user = await services.getUser();
        dispatch(setUser({ ...user.data.result.userData }));
      } catch (error) {
        console.log(error);
      }
    })();
  }, [getDashboard]);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className="home-page flex flex-col gap-6">
        <div className="paper capx-banner flex flex-col gap-6">
          <div className="head flex items-center justify-between">
            <CapxTick />
            <div className="btn-grp flex items-center gap-2">
              <button
                onClick={() => {
                  window.open("https://www.capx.fi/");
                }}
                className="flex justify-center items-center"
              >
                <Web />
              </button>
              <button
                className="flex justify-center items-center"
                onClick={() => {
                  window.open("https://discord.com/invite/HAGATNqT8J");
                }}
              >
                <Discord />
              </button>
              <button
                className="flex justify-center items-center"
                onClick={() => {
                  window.open("https://t.me/capxfi");
                }}
              >
                <Telegram />
              </button>
            </div>
          </div>
          <p className="text">{t("capxBannerText")}</p>
        </div>
        <Menu as="div" className="relative inline-block text-left">
          {({ open }) => (
            <>
              <div className="flex">
                <Menu.Button className="dropdown-btn paper flex items-center justify-between grow">
                  <div className="flex items-center gap-2">
                    <Translation /> <span>{t("changeAppLanguage")}</span>
                  </div>
                  <BsCaretDownFill
                    className={`arrow h-5 w-5 ease-in duration-100 ${
                      open ? "rotate-180" : ""
                    }`}
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="options-wrapper rounded-lg absolute overflow-hidden right-0 z-10 w-full">
                  <div className=" flex flex-col w-full overflow-hidden">
                    <Menu.Item>
                      <button
                        className={`p-3 menu-btn rounded-lg pt-10 -mt-2 relative ${
                          i18n.language === "en" ? "active" : ""
                        }`}
                        onClick={() => {
                          if (i18n.language !== "en") {
                            setIsOpen(true);
                            setSelectedLanguage("en");
                          }
                        }}
                      >
                        English
                      </button>
                    </Menu.Item>
                    <Menu.Item>
                      <button
                        className={`p-3 menu-btn rounded-lg pt-10 -mt-8 ${
                          i18n.language === "hi" ? "active" : ""
                        }`}
                        onClick={() => {
                          if (i18n.language !== "hi") {
                            setIsOpen(true);
                            setSelectedLanguage("hi");
                          }
                        }}
                      >
                        हिंदी
                      </button>
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>

        <button
          className="paper mine-banner flex flex-col gap-3"
          onClick={() => {
            navigate("/mine");
          }}
        >
          <Chip color="211,2,204" className="self-start" text="Capx Mine" />
          <p className="banner-text w-8/12">{t("mineBanner")}</p>
          {showTimer.mine && (
            <Timer
              type="timer"
              leftText={t("unlocksIn")}
              color="#CF01C7"
              borderColor="#FF5CF9"
              rightText={
                dashboardDetails ? (
                  <GetEndTime
                    endTime={dashboardDetails.mineQuestData.unlock_at}
                    handler={() => {
                      setShowTimer((prev) => ({
                        ...prev,
                        mine: !prev.mine,
                      }));
                    }}
                  />
                ) : (
                  "-"
                )
              }
            />
          )}
        </button>
        <button
          className="paper flex flex-col gap-3"
          onClick={() => {
            navigate("/challenges");
          }}
        >
          <div className="flex gap-2">
            <Chip
              color="90,204,0"
              className="self-start"
              text={
                challengeObj[
                  dashboardDetails?.challengeQuestData?.challenge_type
                ]?.chipTitle
              }
            />
            <TokenChip
              text={
                challengeObj[
                  dashboardDetails?.challengeQuestData?.challenge_type
                ]?.reward_string
              }
            />
          </div>
          <p className="banner-text w-8/12">
            {t(
              challengeObj[dashboardDetails?.challengeQuestData?.challenge_type]
                ?.text
            )}
          </p>
          <Timer
            leftText={t("invited")}
            rightText={
              dashboardDetails
                ? `${dashboardDetails.challengeQuestData.challenge_data.invites} / ${dashboardDetails.challengeQuestData.challenge_data.invites_required}`
                : "-"
            }
            color="rgba(90, 204, 0, 0.70)"
            borderColor="#B8FF81"
          />
        </button>
        <button
          className="paper arcade-banner flex flex-col gap-3"
          onClick={() => {
            if (!showTimer?.arcade) navigate("/arcade");
          }}
        >
          <Chip color="243,88,88" className="self-start" text="Arcade" />
          <p className="banner-text w-8/12">{t("arcadeBannerText")}</p>
          {showTimer.arcade && (
            <Timer
              type="timer"
              leftText={t("unlocksIn")}
              rightText={
                dashboardDetails ? (
                  <GetEndTime
                    endTime={dashboardDetails.spinWheelQuestData.unlock_at}
                    handler={() => {
                      setShowTimer((prev) => ({
                        ...prev,
                        arcade: !prev.arcade,
                      }));
                    }}
                  />
                ) : (
                  "-"
                )
              }
              color="#E94A4A"
              borderColor="#FFBEBE"
            />
          )}
        </button>
        {/* <button
          onClick={() => {
            navigate("/weekly-target");
          }}
          className="paper weekly-target-banner flex flex-col gap-3"
        >
          <div className="flex gap-2">
            <Chip
              color="75,173,187"
              className="self-start"
              text="Weekly Target"
            />
            <TokenChip text={"1 xCapx"} />
          </div>
          <p className="banner-text w-8/12">{t("weeklyTargetBannerText")}</p>
          <Timer
            type="timer"
            leftText="ENDS IN"
            color="#0B9FB5"
            borderColor="#58E3F6"
            containedLeft={true}
          />
        </button> */}
        <ConfirmationDialog
          open={isOpen}
          onClose={handleClose}
          selectedLanguage={selectedLanguage}
        />
      </div>
      {loading && <Loader />}
    </>
  );
};

export default Home;
