import React, { useEffect, useState, useCallback } from "react";
import { Capx } from "../../../assets/icons";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import Button from "../../../components/button/Button";
import { toast } from "react-hot-toast";
import { services } from "../../../services";
import { useFireBaseAuth } from "../../../hooks/useFirebaseAuth";
import { useWeb3Auth } from "../../../context/web3Auth/Web3AuthProvider";
import { setUser } from "../../../store/slices/userSlice";
import { useDispatch } from "react-redux";
import { setAuth } from "../../../store/slices/authSlice";
import Loader from "../../../components/loader/Loader";
import { useTranslation } from "react-i18next";

const VerifyOTP = () => {
  const { t } = useTranslation();
  const [OTP, setOTP] = useState("");
  const [loading, setLoading] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [number, setNumber] = useState();

  const { customSignin } = useFireBaseAuth();
  const navigate = useNavigate();
  const { login } = useWeb3Auth();
  const dispatch = useDispatch();

  const getLastGeneratedOTPTime = useCallback(async (num) => {
    setLoading(true);
    try {
      const response = await services.lastGeneratedOTPTime(`+91${num}`);
      const currentTimestamp = Math.floor(Date.now() / 1000);
      const secondsPassed = currentTimestamp - response.data.result.message;

      if (secondsPassed < 60) {
        setSeconds(60 - secondsPassed);
      }
      console.log(response.data.result.message, "this is the response");
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.result?.message) {
        toast.error(error.response.data.result.message);
      } else {
        toast.error(error.message);
      }
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    const wpNumber = localStorage.getItem("whatsappNumber");
    if (!wpNumber) {
      navigate("/onboarding");
    } else {
      setNumber(wpNumber);
      getLastGeneratedOTPTime(wpNumber);
    }
  }, []);

  const handleCodeChange = (code) => {
    setOTP(code);
  };

  const handleVerifyOtp = async () => {
    setLoading(true);
    try {
      const response = await services.validateOtp({
        phone: `+91${number}`,
        otp: OTP,
      });
      localStorage.removeItem("whatsappNumber");
      const signResponse = await customSignin(response.data.result.token);
      const userObj = await services.getUser();
      if (!userObj.data.result.userData) {
        await login();
      } else {
        dispatch(setUser({ ...userObj.data.result.userData }));
      }
      let isUserProfileSet;
      if (
        userObj.data.result.userData &&
        (userObj.data.result.userData.age !== 0 ||
          userObj.data.result.userData.docVersion === 2)
      ) {
        isUserProfileSet = true;
      } else {
        isUserProfileSet = false;
      }
      dispatch(
        setAuth({
          isAuthReady: true,
          isLoggedIn: true,
          emailVerified: signResponse.user.emailVerified,
          user: signResponse.user,
          isUserProfileSet,
        })
      );
    } catch (error) {
      if (error?.response?.data?.result?.message) {
        toast.error(error.response.data.result.message);
      } else {
        toast.error(error.message);
      }
    }
    setLoading(false);
  };

  const handleResendOtp = async () => {
    setLoading(true);
    try {
      const response = await services.resendOtp({
        phone: `+91${number}`,
      });
      getLastGeneratedOTPTime(number);
      toast.success(response.data.result.message);
    } catch (error) {
      if (error?.response?.data?.result?.message) {
        toast.error(error.response.data.result.message);
      } else {
        toast.error(error.message);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const timer =
      seconds > 0 && setInterval(() => setSeconds(seconds - 1), 1000);
    return () => {
      clearInterval(timer);
    };
  }, [seconds]);

  return (
    <>
      <div className="verify-otp-page flex flex-col items-center">
        <div className=" wrapper flex flex-col items-center gap-10 md:gap-8 md:p-3 md:pt-32 pt-6">
          <Capx />
          <div className="flex flex-col gap-10 md:gap-3 self-stretch">
            <div className="flex flex-col gap-3">
              <h3 className="cus-h cus-h-2 text-center ">
                {t("yourPhoneNo")} <br /> {t("verify")} 👀
              </h3>
              <p className="p-text">
                {t("otpCodeSentOnThisNumber")} <br /> +91 <span>{number}</span>
              </p>
            </div>

            <div className="flex flex-col gap-3 ">
              <OtpInput
                value={OTP}
                onChange={handleCodeChange}
                renderSeparator={<span style={{ width: "16px" }}></span>}
                isInputNum={false}
                inputType="number"
                shouldAutoFocus={true}
                containerStyle={{
                  display: "flex",
                  justifyContent: "center",
                  maxWidth: "390px",
                }}
                inputStyle={{
                  background: "#2B2B2B",
                  border: "2px solid #686868",
                  borderRadius: "20px",
                  width: "63px",
                  height: "63px",
                  fontSize: "30px",
                  color: "#E6E6E6",
                  fontWeight: "500",
                  caretColor: "#fff",
                  outline: "none",
                }}
                focusStyle={{
                  border: "1.5px solid rgba(31, 122, 85, 0.4)",
                  outline: "none",
                }}
                renderInput={(props) => <input {...props} />}
              />
              {seconds > 0 ? (
                <p className="resend text-center">
                  {t("resendOtpIn")} {"00"} :{" "}
                  {seconds < 10 ? `0${seconds}` : seconds}s
                </p>
              ) : (
                <p className="resend text-center">
                  {t("dontGetCode")}{" "}
                  <button className="btn-text" onClick={handleResendOtp}>
                    {t("clickToResend")}
                  </button>
                </p>
              )}
            </div>
            <Button
              className="submit-btn self-stratch"
              disabled={OTP.length < 4}
              bRadius={"md"}
              onClick={handleVerifyOtp}
            >
              {t("verifyAndLogin")}
            </Button>
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default VerifyOTP;
