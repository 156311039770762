import React, { useEffect, useState } from "react";
import { Capx } from "../../../assets/icons";
import Button from "../../../components/button/Button";
import { onboarding_panda } from "../../../assets/png";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useNavigate } from "react-router-dom";
import privacyPolicy from "../../../assets/files/Privacy_policy.docx";
import Terms from "../../../assets/files/Terms_of_services.docx";
import LangSwitch from "../../../components/langSwitch/LangSwitch";
import { useTranslation } from "react-i18next";

const Onboarding = () => {
  const [langugae, setLangugae] = useState("en");
  const mediumScreen = useMediaQuery("md");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleLangChange = (e) => {
    var lang = e.target.checked ? "en" : "hi";
    setLangugae(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang);
  };

  useEffect(() => {
    const lang = localStorage.getItem("language");
    setLangugae(lang);
  }, []);

  return (
    <div className="onboarding-page flex flex-col items-center">
      {mediumScreen && (
        <div className="relative w-full">
          <img className="w-full" src={onboarding_panda} alt="panda" />
          <LangSwitch
            onChange={handleLangChange}
            className="absolute on-lang-switch"
            checked={langugae === "en"}
          />
        </div>
      )}

      <div className="wrapper flex flex-col items-center gap-6 md:gap-8 p-8 md:px-0 pt-6 md:pt-32 ">
        <Capx />
        {!mediumScreen && (
          <LangSwitch
            onChange={handleLangChange}
            className="on-lang-switch"
            checked={langugae === "en"}
          />
        )}

        <h2 className="cus-h cus-h-1 text-center px-6 md:px-0 md:mb-0 mb-6">
          {t("EarnYourWayToIndia'sTop1%")}
        </h2>
        <div className="flex flex-col self-stretch gap-5">
          <Button
            onClick={() => {
              navigate("/signup");
            }}
          >
            {t("createAccount")}
          </Button>
          <Button
            onClick={() => {
              navigate("/signin");
            }}
            variant="outlined"
          >
            {t("logIn")}
          </Button>
        </div>
        <p className="bottom-text">
          {t("ByCreatingAnAccountWithCapxYouAgreeToCapxsAndPrivacyPolicy")}
          <br />
          <a href={Terms}>{t("TermsConditions")} </a> {t("and")}{" "}
          <a href={privacyPolicy}>{t("PrivacyPolicy")}</a>
        </p>
      </div>
    </div>
  );
};

export default Onboarding;
