import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useFireBaseAuth } from "../../hooks/useFirebaseAuth";
import { useDispatch } from "react-redux";
import { resetUser } from "../../store/slices/userSlice";
import { resetAuth } from "../../store/slices/authSlice";
import { auth } from "../../firebase/firebase";

const OnboardingLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { signOutUser } = useFireBaseAuth();

  const handleBackBtnClick = async () => {
    if (auth.currentUser) {
      await signOutUser();
      dispatch(resetUser());
      dispatch(resetAuth());
    }
    navigate("/onboarding");
    localStorage.removeItem("whatsappNumber");
  };
  return (
    <main className="onboarding-layout grid grid-cols-10 min-h-screen">
      <div className="left-section col-span-10 md:col-span-4 flex flex-col items-center relative">
        {location.pathname !== "/onboarding" &&
          location.pathname !== "/signup" &&
          location.pathname !== "/signin" &&
          location.pathname !== "/authentication" && (
            <button
              className="on-back-btn absolute z-5 left-6 top-5 flex items-center justify-center rounded-full md:hidden"
              onClick={handleBackBtnClick}
            >
              <FaArrowLeft />
            </button>
          )}

        <Outlet />
      </div>
      <div className="right-section col-span-6 flex justify-center items-center hidden md:flex">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/capx-x-web3auth.appspot.com/o/app_data%2Forg_capx%2Fweb_images%2Fonboarding.svg?alt=media&amp;token=c5bb4490-1632-4c45-8b1f-6abb8410db26"
          alt="Onboarding"
        />
      </div>
    </main>
  );
};

export default OnboardingLayout;
