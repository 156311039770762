import React, { useRef, useState } from "react";
import { Capx, UploadImage } from "../../../assets/icons";
import Input from "../../../components/Input/Input";
import Button from "../../../components/button/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { services } from "../../../services";
import { useFirebaseImageUpload } from "../../../hooks/useFirebaseImageUpload";
import { auth } from "../../../firebase/firebase";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/loader/Loader";
import { t } from "i18next";
import { profile_dummy } from "../../../assets/dummy";

const FILE_SIZE = 5000000;
const SUPPORTED_FORMATS = ["image/jpeg", "image/png"];

const imageValidation = Yup.mixed()
  .test("fileSize", "fileTooLarge", (value) => {
    if (!value) {
      return true;
    } else {
      return value && value.size <= FILE_SIZE;
    }
  })
  .test("fileFormat", "unsupportedFormat", (value) => {
    if (!value) {
      return true;
    } else {
      return value && SUPPORTED_FORMATS.includes(value.type);
    }
  });

const CreateUser = () => {
  const [loading, setLoading] = useState(false);
  const [profileImgPrev, setProfileImgPrev] = useState("");

  const coverInputRef = useRef();
  const { uploadImageToCloud } = useFirebaseImageUpload();
  const navigate = useNavigate();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        imageValidation.validateSync(file);
        const url = URL.createObjectURL(file);
        setProfileImgPrev(url);
        formik.setFieldValue("imageFile", file);
      } catch (error) {
        formik.setFieldError("imageFile", error.message);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      imageFile: "",
      username: "",
      name: "",
      age: "",
    },
    validationSchema: Yup.object().shape({
      imageFile: imageValidation,
      username: Yup.string()
        .required("userNameRequired")
        .min(4, "lengthIsTooShort")
        .max(15, "lengthIsTooLong")
        .matches(/^\w+$/, "onlyAlphaNumericAllowed"),
      name: Yup.string()
        .required("nameIsRequired")
        .max(40, "nameTooLong")
        .matches(/^[A-Za-z\s]+$/, "noNumberInName"),
      age: Yup.number()
        .required("ageIsRequired")
        .min(18, "must18Years")
        .max(99, "validAge"),
    }),
    validateOnChange: true,
    validateOnMount: false,
    validateOnBlur: true,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        let apiData = {
          username: values.username,
          name: values.name,
          age: values.age.toString(),
        };

        if (values.imageFile) {
          const image_url = await uploadImageToCloud(
            `images/profileImage/${auth.currentUser?.uid}`,
            values.imageFile
          );
          apiData.image_url = image_url;
        }

        const response = await services.updateUserProfile({
          data: apiData,
        });
        toast.success(t("yourTransactionSignedSuccessfully"));
        navigate("/congratulations", {
          state: { rewardString: response.data.result.reward },
        });
      } catch (error) {
        console.log(error);
        if (error?.response?.data?.result?.message) {
          toast.error(error.response.data.result.message);
        } else {
          toast.error(error.message);
        }
      }
      setLoading(false);
    },
  });

  return (
    <>
      <main className="create-user-page min-h-screen flex justify-center md:pt-20 pt-16">
        <div className="wrapper flex flex-col md:gap-12 gap-8">
          <Capx className="self-center" />
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col gap-6 grow pb-24"
          >
            <div className="flex md:flex-row flex-col gap-6">
              <div className="upload-img-wrapper flex flex-col gap-2">
                <label>
                  {t("uploadAProfilePicture")}{" "}
                  <span>{`(${t("optional")})`}</span>
                </label>
                <input
                  className="hidden"
                  type="file"
                  name="imageFile"
                  // value={formik.values.imageFile}
                  onChange={handleImageChange}
                  onBlur={formik.handleBlur}
                  ref={coverInputRef}
                  accept="image/png, image/jpeg"
                />
                <div
                  onClick={() => {
                    coverInputRef.current.click();
                  }}
                  className="upload-box flex flex-col justify-center items-center overflow-hidden"
                >
                  {profileImgPrev ? (
                    <img
                      src={profileImgPrev}
                      className="w-full h-full object-cover img-prev"
                      alt="prfile-prev"
                    />
                  ) : (
                    <>
                      <UploadImage />
                      <h4>{t("clickToUpload")}</h4>
                      <p>(JPEG or PNG)</p>
                    </>
                  )}
                </div>

                {formik.errors.imageFile ? (
                  <p className="helper-text">{t(formik.errors.imageFile)}</p>
                ) : (
                  <p className="just-text">
                    {t("DontWorryYouCanAlwaysChangeLater")}
                  </p>
                )}
              </div>

              <div className=" flex flex-col gap-3 md:gap-6  md:justify-center">
                <div>
                  <Input
                    fixedPlaceholder="@"
                    className="inputs"
                    label={`${t("chooseAUsername")} *`}
                    type="text"
                    value={formik.values.username}
                    error={formik.errors.username && formik.touched.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="username"
                    placeholder={t("chooseACoolUniqueUsername")}
                  />
                  <span className="helper-text">
                    {formik.errors.username && formik.touched.username
                      ? t(formik.errors.username)
                      : null}
                  </span>
                </div>
                <div>
                  <Input
                    className="inputs"
                    label={`${t("yourName")} *`}
                    type="text"
                    value={formik.values.name}
                    error={formik.errors.name && formik.touched.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="name"
                    placeholder={t("enterYourNameOrNickname")}
                  />
                  <span className="helper-text">
                    {formik.errors.name && formik.touched.name
                      ? t(formik.errors.name)
                      : null}
                  </span>
                </div>
                <div>
                  <Input
                    className="inputs"
                    label={`${t("yourAge")} *`}
                    type="number"
                    value={formik.values.age}
                    error={formik.errors.age && formik.touched.age}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="age"
                    placeholder={t("pleaseEnterYourAge")}
                  />
                  <span className="helper-text">
                    {formik.errors.age && formik.touched.age
                      ? t(formik.errors.age)
                      : null}
                  </span>
                </div>
              </div>
            </div>
            <div className="grow" />
            <Button
              disabled={!formik.isValid || !formik.dirty}
              className="submit-btn md:self-center"
              bRadius={"md"}
            >
              {t("submitClaimReward")}
            </Button>
          </form>
        </div>
      </main>
      {loading && <Loader />}
    </>
  );
};

export default CreateUser;
