import { initializeApp } from "firebase/app";
import { config } from "../config.js";
import { getAuth } from "firebase/auth";

//initialize firebase config
const firebaseConfig = {
  apiKey: config.APIKeyV2,
  authDomain: config.AuthDomainV2,
  storageBucket: config.storageBucketV2,
  projectId: config.ProjectIdV2,
  messagingSenderId: config.MessagingSenderIdV2,
  appId: config.AppIdV2,
  //   measurementId: config.MeasurementId,
};

//init firebase
const appV2 = initializeApp(firebaseConfig, "appV2");

//init auth
const authV2 = getAuth(appV2);

export { authV2 };
