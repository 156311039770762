import { signInWithCustomToken, signOut } from "firebase/auth";
import { toast } from "react-hot-toast";
import { authV2 } from "../firebase/firebaseV2";
import { services } from "../services";

export const useFireBaseAuthV2 = () => {
  const customSignInV2 = async () => {
    const apiResponse = await services.validateUserInV2();
    const response = await signInWithCustomToken(
      authV2,
      apiResponse.data.result.idToken
    );
    if (!response) {
      throw new Error("Could not complete signin");
    }

    return response;
  };

  const signOutUserV2 = async () => {
    try {
      await signOut(authV2);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  return {
    customSignInV2,
    signOutUserV2,
  };
};
