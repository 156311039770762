import React, { useCallback, useEffect, useState } from "react";
import { Capx } from "../../../assets/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/button/Button";
import Input from "../../../components/Input/Input";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useFireBaseAuth } from "../../../hooks/useFirebaseAuth";
import { services } from "../../../services";
import { useDispatch } from "react-redux";
import { setAuth } from "../../../store/slices/authSlice";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import Loader from "../../../components/loader/Loader";
import { useWeb3Auth } from "../../../context/web3Auth/Web3AuthProvider";
import { auth } from "../../../firebase/firebase";
import { setUser } from "../../../store/slices/userSlice";

const EmailLogin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inviter, setInviter] = useState("");

  const mediumScreen = useMediaQuery("md");
  let { method } = useParams();
  const dispatch = useDispatch();
  const { signInUser, signupUsingEmail, verifyEmail, signOutUser } =
    useFireBaseAuth();
  const { t, i18n } = useTranslation();
  const { login, getUserAccount } = useWeb3Auth();
  const navigate = useNavigate();

  const getInviter = useCallback(async (code) => {
    setLoading(true);
    try {
      const response = await services.getInviter(code);
      setInviter(response.data.result.inviter);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (method !== "signin" && method !== "signup") {
      navigate("/whatsapp/signup");
    }
    const inviter_code = localStorage.getItem("invite_code");
    if (inviter_code && method === "signup") getInviter(inviter_code);
  }, []);

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required("emailIsRequired")
        .matches(/^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/, "invalidEmailAddress"),
      password: Yup.string()
        .required("passwordIsRequired")
        .min(6, "minimum6LettersAreRequiredInPassword"),
    }),
    validateOnChange: true,
    validateOnMount: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      setLoading(true);
      let signResponse;
      try {
        if (method === "signin") {
          signResponse = await signInUser(values.email, values.password);
        } else {
          signResponse = await signupUsingEmail(values.email, values.password);
        }

        const userObj = await services.getUser();
        if (!signResponse.user.emailVerified) {
          await verifyEmail();
        }
        if (!userObj.data.result.userData) {
          if (signResponse.user.emailVerified) {
            const idToken = await auth.currentUser.getIdToken(true);
            await login(idToken);
          }
        } else {
          dispatch(setUser({ ...userObj.data.result.userData }));
        }
        let isUserProfileSet;
        if (
          userObj.data.result.userData &&
          (userObj.data.result.userData.age !== 0 ||
            userObj.data.result.userData.docVersion === 2)
        ) {
          isUserProfileSet = true;
        } else {
          isUserProfileSet = false;
        }
        dispatch(
          setAuth({
            isAuthReady: true,
            isLoggedIn: true,
            emailVerified: signResponse.user.emailVerified,
            user: signResponse.user,
            isUserProfileSet,
          })
        );
      } catch (error) {
        console.log(error);
        if (error.message === "Firebase: Error (auth/too-many-requests).") {
          await signOutUser();
        }
        toast.error(error.message);
      }

      setLoading(false);
    },
  });

  const handleShowPassword = (e) => {
    setShowPassword(e.target.checked);
  };

  return (
    <>
      <div className="email-login-page page flex flex-col items-center">
        <div className=" wrappper  min-h-screen flex flex-col items-center gap-10 md:gap-8 md:p-3 md:pt-32 pt-6 px-8">
          <Capx />
          <div className="flex flex-col gap-3">
            <h3 className="cus-h cus-h-2 text-center px-12 -mx-8">
              {method === "signin" ? (
                <>{t("welcomeBack!")}</>
              ) : inviter && inviter.length > 0 ? (
                <>{t("congratulation")}</>
              ) : (
                <>{t("welcomeToCapxApp")}</>
              )}
            </h3>
            {inviter && inviter.length > 0 && method === "signup" ? (
              <div className="inviter-box self-center flex flex-col items-center gap-1">
                <h3 className="heading">{t("youHaveBeenInvitedBy")}</h3>
                <p className="inviter">@{inviter}</p>
              </div>
            ) : (
              <p className="p-text">
                {method === "signin"
                  ? t("Let'sGetYouBackInTheGame")
                  : t("let'sBeginYourWeb3Journey!🚀")}
              </p>
            )}
          </div>
          {mediumScreen && <div className="grow" />}
          <form
            onSubmit={formik.handleSubmit}
            className="form grow flex flex-col self-stretch gap-3"
          >
            <div className="flex flex-col">
              <Input
                className="self-stretch"
                label={t("enterYourEmail") + " *"}
                placeholder={t("enterYourEmail")}
                type="text"
                name="email"
                error={formik.errors.email && formik.touched.email}
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.errors.email && formik.touched.email ? (
                <p className="helper-text">{t(formik.errors.email)}</p>
              ) : null}
            </div>
            <div className="flex flex-col">
              <Input
                className="self-stretch"
                label={t("password") + " *"}
                placeholder={t("password")}
                type={showPassword ? "text" : "password"}
                name="password"
                error={formik.errors.password && formik.touched.password}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.password && formik.touched.password ? (
                <p className="helper-text">{t(formik.errors.password)}</p>
              ) : null}
            </div>
            <div className="flex items-start justify-between">
              <div className="show-password flex items-center gap-1">
                <input
                  type="checkbox"
                  checked={showPassword}
                  onChange={handleShowPassword}
                />
                <span>{t("showPassword")}</span>
              </div>
              {method === "signin" ? (
                <Link className="direct" to={"/forgot-password"}>
                  {t("forgotPassword")}
                </Link>
              ) : (
                <Link className="direct text-right" to="/email/signin">
                  {t("alreadyAMember")}
                  <br /> {t("logIn")}
                </Link>
              )}
            </div>
            {mediumScreen && <div className="grow" />}
            <Button
              disabled={!formik.isValid || !formik.dirty}
              className="md:mt-6 sub-btn"
              type="submit"
              bRadius={"md"}
            >
              {method === "signin" ? t("logIn") : t("createAccount")}
            </Button>
          </form>
          {mediumScreen && <div className="grow" />}
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default EmailLogin;
