import { customAxios } from "./serverCall";

const getUser = () => {
  return customAxios.get(`user/getUser`);
};

const getOnboardingQuiz = () => {
  return customAxios.get(`user/getOnboardingQuiz`);
};
const completeOnboardingQuiz = (data) => {
  return customAxios.post(`user/completeOnboardingQuiz`, data);
};

const createUser = (data) => {
  return customAxios.post(`user/createUser`, data);
};

const updateUserProfile = (data) => {
  return customAxios.post(`user/updateUserProfile`, data);
};

const sentWhatsappOtp = (data) => {
  return customAxios.post(`whatsapp/sendOTP`, data);
};

const validateOtp = (data) => {
  return customAxios.post(`whatsapp/validateOTP`, data);
};

const resendOtp = (data) => {
  return customAxios.post(`whatsapp/resendOTP`, data);
};

const getSpinWheelQuest = () => {
  return customAxios.get(`user/getSpinWheelQuest`);
};

const completeSpinWheelQuest = () => {
  return customAxios.post(`user/completeSpinWheelQuest`);
};

const getMineQuest = () => {
  return customAxios.get(`user/getMineQuest`);
};

const completeMineQuest = () => {
  return customAxios.post(`user/completeMineQuest`);
};

const getInviter = (code) => {
  return customAxios.get(`user/getInviter?referral_code=${code}`);
};
const getDashboard = () => {
  return customAxios.get(`user/getDashboard`);
};

const getChallenges = () => {
  return customAxios.get(`user/getChallenges`);
};

const getBonusChallenge = () => {
  return customAxios.get(`user/getBonusChallenge`);
};

const registerBonusChallenge = () => {
  return customAxios.post(`user/registerBonusChallenge`);
};

const fetchUserWallet = (skip, length) => {
  return customAxios.get(`user/fetchUserWallet?skip=${skip}&length=${length}`);
};

const linkDiscord = (code) => {
  return customAxios.get(`discord/link-auth?code=${code}`);
};

const linkTwitter = () => {
  return customAxios.post(`user/linkTwitter`);
};

const updateUserDetails = (data) => {
  return customAxios.post(`user/updateUserDetails`, data);
};

const submitClaim = (data) => {
  return customAxios.post(`user/submitClaim`, data);
};

const validateUserInV2 = () => {
  return customAxios.get(`web3auth/auth`);
};

const lastGeneratedOTPTime = (number) => {
  const encoded = encodeURIComponent(number);
  return customAxios.get(`whatsapp/lastGeneratedOTP?phone=${encoded}`);
};

const services = {
  getUser,
  getOnboardingQuiz,
  createUser,
  sentWhatsappOtp,
  validateOtp,
  resendOtp,
  completeOnboardingQuiz,
  updateUserProfile,
  getSpinWheelQuest,
  completeSpinWheelQuest,
  getMineQuest,
  completeMineQuest,
  getInviter,
  getChallenges,
  getDashboard,
  getBonusChallenge,
  registerBonusChallenge,
  fetchUserWallet,
  linkDiscord,
  linkTwitter,
  updateUserDetails,
  submitClaim,
  validateUserInV2,
  lastGeneratedOTPTime,
};

export { services };
