import React from "react";
import { FaLock } from "react-icons/fa";

const Timer = ({
  type,
  leftText,
  rightText,
  color,
  borderColor,
  containedLeft,
}) => {
  return (
    <div
      className="capx-timer flex overflow-hidden self-stretch"
      style={{ borderColor: borderColor }}
    >
      {type === "locked" ? (
        <div className="locked flex items-center justify-center grow gap-2">
          <FaLock />
          <span>LOCKED</span>
        </div>
      ) : (
        <>
          <span
            style={{
              borderColor: borderColor,
              backgroundColor: containedLeft ? color : "",
            }}
            className="title"
          >
            {leftText}
          </span>
          {type === "timer" ? (
            <span
              style={{ backgroundColor: !containedLeft ? color : "" }}
              className="time grow"
            >
              {rightText ? rightText : "-"}
            </span>
          ) : (
            <span
              style={{ backgroundColor: !containedLeft ? color : "" }}
              className="time grow"
            >
              {rightText}
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default Timer;
