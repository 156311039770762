import React from "react";

const Button = ({
  children,
  onClick,
  className,
  variant,
  bRadius,
  ...rest
}) => {
  return (
    <button
      {...rest}
      className={`capx-btn ${
        variant === "outlined" ? "outlined-capx-btn" : "contained-capx-btn"
      } ${bRadius === "md" ? "r-md" : "r-full"} ${className} `}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
