import React from "react";
import { Coin } from "../../../assets/icons";

const TokenChip = ({ text, className }) => {
  return (
    <div className={`token-chip flex items-center gap-1.5 ${className}`}>
      <Coin />
      <span>{text}</span>
    </div>
  );
};

export default TokenChip;
