import React, { createContext, useContext, useEffect } from "react";
import { ADAPTER_EVENTS, WALLET_ADAPTERS } from "@web3auth/base";
import { ethers } from "ethers";
import Loader from "../../components/loader/Loader";
import { authV2 } from "../../firebase/firebaseV2";
import { useFireBaseAuthV2 } from "../../hooks/useFirebaseAuthV2";
import web3auth from "../../web3auth/web3auth";

// Create a Context
const Web3AuthContext = createContext();

export const Web3AuthProvider = ({ children }) => {
  const { customSignInV2 } = useFireBaseAuthV2();

  useEffect(() => {
    (async () => {
      try {
        web3auth.on(ADAPTER_EVENTS.ERRORED, (error) => {
          console.error(
            "some error or user has cancelled login request",
            error
          );
        });
        // window.history.pushState({}, "", location);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const login = async () => {
    if (!authV2.currentUser?.accessToken) {
      await customSignInV2();
    }
    const idToken = await authV2.currentUser.getIdToken(true);
    try {
      const connect = await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
        loginProvider: "jwt",
        extraLoginOptions: {
          id_token: idToken,
          verifierIdField: "sub",
          domain: window.location.host,
        },
      });
    } catch (error) {
      console.log(error, web3auth);
    }
  };

  const getUserAccount = async () => {
    if (web3auth?.provider) {
      const provider = new ethers.providers.Web3Provider(web3auth.provider);
      const signer = provider.getSigner();
      const userAccounts = await signer.getAddress();
      return userAccounts;
    }
  };

  const logout = async () => {
    if (web3auth) {
      await web3auth.logout();
    }
  };

  return (
    <Web3AuthContext.Provider
      value={{ web3auth, login, logout, getUserAccount }}
    >
      {children}
    </Web3AuthContext.Provider>
  );
};

// Create a Hook for easy usage of the context
export const useWeb3Auth = () => useContext(Web3AuthContext);
