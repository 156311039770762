import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer/Footer";
import { Outlet, useLocation } from "react-router-dom";

const InternalLayout2 = () => {
  const location = useLocation();
  return (
    <div className="flex justify-center">
      <div className="internal-layout flex flex-col h-screen">
        <Header />
        <main className="main grow flex flex-col">
          <div className="wrapper p-6 grow flex flex-col">
            <Outlet />
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default InternalLayout2;
