import React from "react";

const Input = (props) => {
  const {
    label,
    type,
    placeholder,
    error,
    name,
    className,
    value,
    onChange,
    onBlur,
    success,
    fixedPlaceholder,
    disabled,
  } = props;

  return (
    <div
      className={`capx-input flex flex-col ${className} ${
        error ? "capx-input-error" : ""
      } ${success ? "capx-input-success" : ""}`}
    >
      <label htmlFor={name} className="">
        {label}
      </label>
      <div className="flex items-center gap-2">
        {fixedPlaceholder && (
          <span className="fixedp mt-2">{fixedPlaceholder}</span>
        )}

        <input
          name={name}
          id={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          className={`w-full`}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default Input;
