import React from "react";

const Stepper = ({ steps, currentStep }) => {
  return (
    <div className="capx-stepper flex items-center gap-4">
      {steps &&
        Array(steps)
          .fill("")
          .map((el, ind) => {
            return (
              <div
                className={`step-bar grow ${
                  currentStep >= ind + 1 ? "active-step" : ""
                }`}
              />
            );
          })}
    </div>
  );
};

export default Stepper;
