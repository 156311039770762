import { Dialog } from "@mui/material";
import React from "react";
import InternalLayout2 from "../../layouts/internalLayout2";
import Button from "../button/Button";
import { t } from "i18next";
import { congratulation } from "../../assets/png";

const CongratulationModal = ({
  open,
  onClose,
  handleSubmit,
  heading,
  text,
  btnText,
  helperText,
  btnVariant,
  showFooter,
  maxCount,
  count,
  helperImage,
}) => {
  return (
    <Dialog
      className="flex flex-row justify-center items-center"
      fullScreen
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          background: "#0C0E12",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "0.7rem",
          width: "100vw",
          height: "100vh",
          margin: 0,
        },
      }}
    >
      <InternalLayout2
        handleBack={onClose}
        showFooter={showFooter}
        maxCount={maxCount}
        count={count}
      >
        <div className="congratulation-modal flex flex-col gap-6">
          <div className="rounded-full cong-box p-3 layer-1 self-center">
            <div className="rounded-full p-3 layer-2">
              <div className="p-5 rounded-full flex flex-col gap-1 layer-3">
                <img
                  className="self-center"
                  src={helperImage ? helperImage : congratulation}
                  alt="helper-image"
                />
                <div className="flex flex-col mt-2 gap-1">
                  <h5 className="text-center">
                    {heading ? heading : t("woohoo!")}
                  </h5>
                  <p className="text-center px-5">{text}</p>
                </div>
              </div>
            </div>
          </div>
          <Button
            className="sub-btn"
            variant={btnVariant}
            bRadius={"md"}
            onClick={handleSubmit}
          >
            {btnText}
          </Button>
          <p className="helper-text text-center">{helperText}</p>
        </div>
      </InternalLayout2>
    </Dialog>
  );
};

export default CongratulationModal;
