import React, { useRef } from "react";

const LangSwitch = ({ className, onChange, checked }) => {
  const checkBoxRef = useRef();
  return (
    <button
      onClick={() => {
        checkBoxRef.current.click();
      }}
      className={`flex lang-switch items-center ${
        checked ? "checked" : "unchecked"
      } ${className} `}
    >
      <div className="relative w-full h-full flex items-center">
        <input
          onChange={onChange}
          checked={checked}
          ref={checkBoxRef}
          type="checkbox"
          className="hidden"
        />
        <span className="spacer" />
        <span className="absolute text eng">English</span>
        <span className="absolute text hin">हिंदी</span>
        <span className="ball rounded-full p-3 h-full"></span>
      </div>
    </button>
  );
};

export default LangSwitch;
