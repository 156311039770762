import axios from "axios";
import { config } from "../config";
import { auth } from "../firebase/firebase";

const customAxios = axios.create({
  baseURL: config.API_URL,
});

const requestHandler = async (request) => {
  if (auth.currentUser?.accessToken) {
    if (auth.currentUser.stsTokenManager.expirationTime <= Date.now()) {
      await auth.currentUser.getIdToken(true);
    }
    request.headers.Authorization = `Bearer ${auth.currentUser.accessToken}`;
  }
  return request;
};

const requestErrorHandler = (error) => {
  return Promise.reject(error);
};

const responseHandler = (response) => {
  return response;
};
const responseErrorHandler = (error) => {
  return Promise.reject(error);
};

customAxios.interceptors.request.use(requestHandler, requestErrorHandler);

customAxios.interceptors.response.use(responseHandler, responseErrorHandler);

export { customAxios };
