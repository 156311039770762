import React, { useCallback, useEffect, useState } from "react";
import PageTitle from "../../components/pageTitle/PageTitle";
import Button from "../../components/button/Button";
import { services } from "../../services";
import { toast } from "react-hot-toast";
import Loader from "../../components/loader/Loader";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/slices/userSlice";
import CongratulationModal from "../../components/congratulationModal/CongratulationModal";
import { useTranslation } from "react-i18next";
import { congratulation, better_luck_next_time } from "../../assets/png";

function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min - 1)) + min + 1;
}

const Arcade = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isWheelSpinning, setIsWheelSpinning] = useState(false);
  const [isBtnDisabled, setisBtnDisabled] = useState(true);
  const [rotationDegree, setRotationDegree] = useState(0);
  const [addTransition, setAddTransition] = useState(true);
  const [spinWheelData, setSpinWheelData] = useState(null);
  const [rewardString, setRewardString] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();

  const paramDegree = [
    {
      value: "0 xCAPX",
      minDeg: 123,
      maxDeg: 177,
    },
    {
      value: "0.1 xCAPX",
      minDeg: 183,
      maxDeg: 237,
    },
    {
      value: "0.5 xCAPX",
      minDeg: 183,
      maxDeg: 237,
    },
    {
      value: "1 xCAPX",
      minDeg: 63,
      maxDeg: 117,
    },
    {
      value: "5 xCAPX",
      minDeg: 63,
      maxDeg: 117,
    },
    {
      value: "10 xCAPX",
      minDeg: 3,
      maxDeg: 57,
    },
    {
      value: "0 xCAPX",
      minDeg: 303,
      maxDeg: 357,
    },
    {
      value: "Extra Spins!",
      minDeg: 243,
      maxDeg: 297,
    },
  ];

  const handleModalClose = () => {
    setIsModalOpen(false);
    setRewardString(null);
  };

  const getSpinWheelQuest = useCallback(async () => {
    setLoading(true);
    try {
      const response = await services.getSpinWheelQuest();
      setSpinWheelData(response.data.result.questData);
      response.data.result.questData.usedSpins ===
      response.data.result.questData.maxSpins
        ? setisBtnDisabled(true)
        : setisBtnDisabled(false);
    } catch (error) {
      if (error?.response?.data?.result?.message) {
        toast.error(error.response.data.result.message);
      } else {
        toast.error(error.message);
      }
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    getSpinWheelQuest();
  }, [getSpinWheelQuest]);

  const startSpinning = async () => {
    setAddTransition(true);
    setIsWheelSpinning(true);
    setRotationDegree(10000);
    setisBtnDisabled(true);
    let timer;
    try {
      timer = setInterval(() => {
        setRotationDegree((prevDegree) => prevDegree + 10);
      }, 50);

      const response = await services.completeSpinWheelQuest();
      setSpinWheelData(response.data.result.questData);
      setRewardString(response.data.result.reward);
      const responseValue = paramDegree
        .sort(() => Math.random() - 0.5)
        .filter((el) => {
          return el.value === response.data.result.reward;
        })[0];
      setRewardString(response.data.result.reward);
      const stoppingAngle = getRandomNumber(
        responseValue.minDeg,
        responseValue.maxDeg
      );

      setRotationDegree((prevDegree) => {
        const rest = 360 - (prevDegree % 360);
        return rest + prevDegree + stoppingAngle;
      });
      clearInterval(timer);
    } catch (error) {
      clearInterval(timer);
      if (error?.response?.data?.result?.message) {
        toast.error(error.response.data.result.message);
      } else {
        toast.error(error.message);
      }
      setIsWheelSpinning(false);
    }
  };

  const handleTransitionEnd = async () => {
    try {
      setAddTransition(false);
      setRotationDegree((prev) => prev % 360);
      setTimeout(() => {
        setIsModalOpen(true);
        setisBtnDisabled(false);
      }, 2000);
      const user = await services.getUser();
      dispatch(setUser({ ...user.data.result.userData }));
    } catch (error) {
      toast.error(error.message);
    }
    setIsWheelSpinning(false);
  };

  return (
    <>
      {isWheelSpinning && <div className="arcade-layer" />}
      <div className="arcade-page flex flex-col">
        <PageTitle title="Arcade" />
        <div className="flex flex-col gap-6 pt-6 overflow-hidden">
          {
            <div className="spinnig-wheel-wrapper flex justify-center relative">
              <div className="spin-center">Spin</div>
              <div
                className={`capx-spinnig-wheel self-center rounded-full ${
                  addTransition ? "wheel-transition" : ""
                } `}
                style={{
                  transform: `rotate(${rotationDegree}deg)`,
                }}
                onTransitionEnd={handleTransitionEnd}
              >
                <div className="layer" />
                <div
                  className="reward-number"
                  style={{ "--i": "1", "--clr": "#ff6b81" }}
                >
                  <span style={{ "--fsize": "1.5rem" }}>Oops!</span>
                </div>
                <div
                  className="reward-number"
                  style={{ "--i": "2", "--clr": "#fff" }}
                >
                  <span style={{ "--fsize": "1rem" }}>Lucky Day</span>
                </div>
                <div
                  className="reward-number"
                  style={{ "--i": "3", "--clr": "#ff6b81" }}
                >
                  <span style={{ "--fsize": "1.2rem" }}>Awesome</span>
                </div>
                <div
                  className="reward-number"
                  style={{ "--i": "4", "--clr": "#fff" }}
                >
                  <span style={{ "--fsize": "1.5rem" }}>Oops!</span>
                </div>
                <div
                  className="reward-number"
                  style={{ "--i": "5", "--clr": "#ff6b81" }}
                >
                  <span style={{ "--fsize": "1.5rem" }}>Crazy</span>
                </div>
                <div
                  className="reward-number"
                  style={{ "--i": "6", "--clr": "#fff" }}
                >
                  <span style={{ "--fsize": "1.5rem" }}>Wow!</span>
                </div>
              </div>
            </div>
          }
          <p className="text-center">
            Spins :{" "}
            {spinWheelData && (
              <>
                {spinWheelData?.usedSpins} / {spinWheelData?.maxSpins}
              </>
            )}
          </p>

          <Button
            disabled={
              spinWheelData?.usedSpins === spinWheelData?.maxSpins ||
              isBtnDisabled
            }
            onClick={startSpinning}
            className="sb-btn"
            bRadius={"md"}
          >
            Spin
          </Button>
        </div>
      </div>
      {loading && <Loader />}
      <CongratulationModal
        open={isModalOpen && rewardString}
        onClose={handleModalClose}
        btnText={t("back")}
        heading={rewardString === "0 xCAPX" ? t("oops") : ""}
        btnVariant="outlined"
        text={
          rewardString === "0 xCAPX" ? (
            t("betterLuckNextTime")
          ) : rewardString === "Extra Spins!" ? (
            <>
              {t("youJustEarned")} <br /> 2 Extra Spins
            </>
          ) : (
            <>
              {t("youJustEarned")} <br /> {rewardString}
            </>
          )
        }
        handleSubmit={handleModalClose}
        showFooter={true}
        helperImage={
          rewardString === "0 xCAPX" ? better_luck_next_time : congratulation
        }
      />
    </>
  );
};

export default Arcade;
