import { Dialog } from "@mui/material";
import React from "react";
import Button from "../../../../components/button/Button";
import { useTranslation } from "react-i18next";

const ConfirmationDialog = (props) => {
  const { t, i18n } = useTranslation();
  const { open, onClose, selectedLanguage } = props;

  const handleLangChange = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang);
  };

  return (
    <>
      <Dialog
        className="flex flex-row justify-center items-center"
        open={open}
        onClose={onClose}
        PaperProps={{
          style: {
            background: "#17191C",
            borderRadius: "1.25rem",
            padding: "1.25rem 1.5rem",
            display: "flex",
            flexDirection: "column",
            gap: "0.7rem",
            minWidth: "340px",
          },
        }}
      >
        <div className="dialog-box flex flex-col gap-5 justify-center items-center">
          <h4 className="text-white text-center">{`${t("areYouSure")}?`}</h4>

          <div className="btn-grp flex gap-6 justify-center">
            <Button
              bRadius="md"
              className="btn yes-button basis-1/2"
              onClick={() => {
                onClose();
                handleLangChange(selectedLanguage);
              }}
            >
              {t("yes")}
            </Button>
            <Button
              bRadius="md"
              variant="outlined"
              className="btn no-button basis-1/2"
              onClick={onClose}
            >
              {t("no")}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;
