import { BrowserRouter } from "react-router-dom";
import Routes from "./routes/routes";
import { useFireBaseAuthstate } from "./hooks/useFirebaseAuthState";

import Notification from "./components/notification/Notification";
import { useSelector } from "react-redux";
import Loader from "./components/loader/Loader";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

function App() {
  useFireBaseAuthstate();
  const isAuthReady = useSelector((state) => state.auth.isAuthReady);
  return (
    <BrowserRouter>
      {isAuthReady ? <Routes /> : <Loader />}
      <Notification />
    </BrowserRouter>
  );
}

export default App;
