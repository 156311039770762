import React from "react";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

const Pagination = ({ activePage, maxPages, handler }) => {
  const clickHandler = (pageNumber) => {
    handler(pageNumber);
  };

  return (
    <div className="capx-pagination flex items-center justify-center  gap-1.5">
      <button
        disabled={activePage === 1}
        className="flex items-center gap-1"
        onClick={() => {
          clickHandler(activePage - 1);
        }}
      >
        <FaAngleLeft />
        <span>Prev</span>
      </button>
      {maxPages < 6 ? (
        Array(maxPages)
          .fill("")
          .map((el, ind) => {
            return (
              <button
                className={`flex items-center gap-1 ${
                  ind + 1 === activePage ? "active" : ""
                }`}
                onClick={() => {
                  clickHandler(ind + 1);
                }}
              >
                <span>{ind + 1}</span>
              </button>
            );
          })
      ) : maxPages - activePage < 3 ? (
        <>
          <button
            value={maxPages}
            className="flex items-center gap-1"
            onClick={() => {
              clickHandler(1);
            }}
          >
            1
          </button>
          <p>...</p>
          {Array(4)
            .fill("")
            .map((el, ind) => {
              return (
                <button
                  value={activePage}
                  className={`flex items-center gap-1 ${
                    activePage === maxPages - 3 + ind ? "active" : ""
                  }`}
                  onClick={() => {
                    clickHandler(maxPages - 3 + ind);
                  }}
                >
                  {maxPages - 3 + ind}
                </button>
              );
            })}
        </>
      ) : maxPages > 5 && activePage < 4 ? (
        <>
          {Array(4)
            .fill("")
            .map((el, ind) => {
              return (
                <button
                  value={activePage}
                  className={`flex items-center gap-1 ${
                    activePage === ind + 1 ? "active" : ""
                  }`}
                  onClick={() => {
                    clickHandler(ind + 1);
                  }}
                >
                  {ind + 1}
                </button>
              );
            })}

          <p>...</p>
          <button
            value={maxPages}
            className="flex items-center gap-1"
            onClick={() => {
              clickHandler(maxPages);
            }}
          >
            {maxPages}
          </button>
        </>
      ) : (
        <>
          <button
            value={activePage}
            className={`flex items-center gap-1 ${
              activePage === 1 ? "active" : ""
            }`}
            onClick={() => {
              clickHandler(1);
            }}
          >
            1
          </button>
          <p>...</p>
          <button
            className={`flex items-center gap-1 `}
            onClick={() => {
              clickHandler(activePage - 1);
            }}
          >
            {activePage - 1}
          </button>
          <button className={`flex items-center gap-1 ${"active"}`}>
            {activePage}
          </button>
          <button
            className={`flex items-center gap-1 `}
            onClick={() => {
              clickHandler(activePage + 1);
            }}
          >
            {activePage + 1}
          </button>

          <p>...</p>
          <button
            value={maxPages}
            className="flex items-center gap-1"
            onClick={() => {
              clickHandler(maxPages);
            }}
          >
            {maxPages}
          </button>
        </>
      )}

      <button
        value={activePage}
        disabled={activePage === maxPages}
        className="flex items-center gap-1"
        onClick={() => {
          clickHandler(activePage + 1);
        }}
      >
        <span>Next</span>
        <FaAngleRight />
      </button>
    </div>
  );
};

export default Pagination;
