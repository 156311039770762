import React from "react";
import { Link, NavLink } from "react-router-dom";
import {
  ActiveHome,
  ActiveMine,
  ActiveTarget,
  Home,
  Mine,
  Target,
} from "../../assets/icons";

const Footer = () => {
  return (
    <footer className="footer flex items-center px-6 justify-between">
      <NavLink className="tab" to="/home">
        <Home className="icon" />
        <ActiveHome className="active-icon" />
      </NavLink>
      <NavLink className="tab" to="/mine">
        <Mine className="icon" />
        <ActiveMine className="active-icon" />
      </NavLink>
      <NavLink className="tab" to="/challenges">
        <Target className="icon" />
        <ActiveTarget className="active-icon" />
      </NavLink>
    </footer>
  );
};

export default Footer;
