import React, { useCallback, useEffect, useState } from "react";
import { Capx } from "../../../assets/icons";
import Button from "../../../components/button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { services } from "../../../services";
import { toast } from "react-hot-toast";
import Loader from "../../../components/loader/Loader";

const WhatsappLogin = () => {
  const [number, setNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [regex, setRegex] = useState(
    /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/
  );
  const [isSubmitBtnDisable, serIsSubmitBtnDisable] = useState(true);
  const [inviter, setInviter] = useState("");

  let { method } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getInviter = useCallback(async (code) => {
    setLoading(true);
    try {
      const response = await services.getInviter(code);
      setInviter(response.data.result.inviter);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (method !== "signin" && method !== "signup") {
      navigate("/whatsapp/signup");
    }
    const inviter_code = localStorage.getItem("invite_code");
    if (inviter_code && method === "signup") getInviter(inviter_code);
  }, []);

  const handleNumberChange = (e) => {
    if (e.target.value.length <= 10) {
      if (e.target.value.match(regex)) {
        serIsSubmitBtnDisable(false);
      } else {
        serIsSubmitBtnDisable(true);
      }
      setNumber(e.target.value);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await services.sentWhatsappOtp({
        phone: `+91${number}`,
      });
      navigate("/verify-otp");
      localStorage.setItem("whatsappNumber", number);
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.result?.message) {
        toast.error(error.response.data.result.message);
      } else {
        toast.error(error.message);
      }
    }
    setLoading(false);
  };

  return (
    <>
      <div className="whatsapp-login-page flex flex-col items-center">
        <div className=" wrappper flex flex-col items-center gap-10 md:gap-8 md:p-3 md:pt-32 pt-6">
          <Capx />
          <div className="flex flex-col gap-3">
            <h3 className="cus-h cus-h-2 text-center px-12">
              {method === "signin" ? (
                <>{t("welcomeBack!")}</>
              ) : inviter && inviter.length > 0 ? (
                <> {t("congratulation")} 🔥</>
              ) : (
                <>{t("welcomeToCapxApp")}</>
              )}
            </h3>
            {inviter && inviter.length > 0 && method === "signup" ? (
              <div className="inviter-box self-center flex flex-col items-center gap-1">
                <h3 className="heading">{t("youHaveBeenInvitedBy")}</h3>
                <p className="inviter">@{inviter}</p>
              </div>
            ) : (
              <p className="p-text">
                {method === "signin"
                  ? t("Let'sGetYouBackInTheGame")
                  : t("let'sBeginYourWeb3Journey!🚀")}
              </p>
            )}
          </div>
          <form
            onSubmit={handleFormSubmit}
            className="number-box flex md:mt-0 mt-6"
          >
            <div className="flex flex-col">
              <label>{t("enterYourWhatsappNo")}</label>
              <div className="input-wrapper flex gap-1">
                <span>+91</span>
                <input
                  onChange={handleNumberChange}
                  autoFocus
                  value={number}
                  maxLength={10}
                  type="number"
                />
              </div>
            </div>
            <Button
              disabled={isSubmitBtnDisable}
              className="submit-btn"
              bRadius="md"
            >
              {t("receiveOtp")}
            </Button>
          </form>
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default WhatsappLogin;
