import React, { useCallback, useEffect, useRef, useState } from "react";
import { Capx } from "../../../assets/icons";
import {
  akshay,
  congratulation_dummy,
  play_dummy,
} from "../../../assets/dummy";
import Button from "../../../components/button/Button";
import Stepper from "../../../components/stepper/Stepper";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { services } from "../../../services";
import Loader from "../../../components/loader/Loader";
import { useSelector } from "react-redux";

const OnboardingQuiz = () => {
  const [isQuizStarted, setIsQuizStarted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [isQUizCompleted, setisQUizCompleted] = useState(false);
  const [rewardString, setRewardString] = useState("second");

  const { t } = useTranslation();
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const earned_rewards = useSelector((state) => state.user.earned_rewards);

  const handleBeginQuiz = () => {
    setIsQuizStarted(true);
  };

  const getOnboardingQuiz = useCallback(async () => {
    setLoading(true);
    try {
      const response = await services.getOnboardingQuiz();
      setQuestions(response.data.result.questData.questDetails.questions);
      setOptions(response.data.result.questData.questDetails.options);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (earned_rewards > 0) {
      navigate("/create-user");
    } else {
      getOnboardingQuiz();
      if (videoRef.current) {
        videoRef.current.play();
      }
    }
  }, [getOnboardingQuiz]);

  const handleQuizSubmit = async () => {
    setLoading(true);
    try {
      const response = await services.completeOnboardingQuiz({
        data: { answers: [...selectedAnswers, selectedAnswer] },
      });
      setRewardString(response.data.result.reward);
      toast.success(t("yourTransactionSignedSuccessfully"));
      setisQUizCompleted(true);
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.result?.message) {
        toast.error(error.response.data.result.message);
      } else {
        toast.error(error.message);
      }
    }
    setLoading(false);
  };

  const handleOptionSelect = (answer) => {
    setSelectedAnswer(answer);
  };

  const handleAnswerSubmit = () => {
    setSelectedAnswers((prev) => [...prev, selectedAnswer]);
    setSelectedAnswer("");
  };

  const handleSkipbtn = async () => {
    navigate("/create-user");
  };

  return (
    <>
      <main className="onboarding-quiz-page min-h-screen flex justify-center md:pt-20 pt-16">
        <div className="wrapper flex flex-col md:gap-12 gap-8 px-8 grow justify-center items-center">
          <Capx className="self-center" />
          {isQUizCompleted ? (
            <div className="congratulation flex flex-col items-center grow gap-16 pb-10">
              <div className="grow" />
              <div className="options flex flex-col gap-6">
                <img
                  className="cong-img rounded-full  self-center"
                  src={congratulation_dummy}
                  alt="dummy"
                />
                <p className="cong-text text-center px-10">
                  {t("woohoo!")}
                  <br />
                  {t("youJustEarned")}
                  <br />
                  {rewardString}
                </p>
              </div>
              <div className="grow" />
              <Button
                onClick={handleSkipbtn}
                className="btns self-stretch"
                bRadius={"md"}
              >
                {t("setupProfile")}
              </Button>
            </div>
          ) : isQuizStarted ? (
            <div className="content flex flex-col grow gap-16 pb-10">
              <Stepper
                steps={questions.length}
                currentStep={selectedAnswers.length + 1}
              />
              <h3 className="page-heading">
                {t(questions[selectedAnswers.length])}
              </h3>
              <div className="options flex flex-col gap-6">
                {options[selectedAnswers.length].options.map((opt) => {
                  return (
                    <button
                      onClick={() => {
                        handleOptionSelect(opt);
                      }}
                      className={`option flex items-center justify-between ${
                        selectedAnswer.split("|")[0] === opt.split("|")[0]
                          ? "active"
                          : ""
                      }`}
                    >
                      <span>{t(opt.split("|")[0])}</span>
                      <span>{opt.split("|")[1]}</span>
                    </button>
                  );
                })}
              </div>
              <div className="grow" />
              {questions.length - 1 > selectedAnswers.length ? (
                <Button
                  onClick={handleAnswerSubmit}
                  className="btns"
                  bRadius={"md"}
                  disabled={!selectedAnswer}
                >
                  {t("submit")}
                </Button>
              ) : (
                <Button
                  onClick={handleQuizSubmit}
                  className="btns"
                  bRadius={"md"}
                  disabled={!selectedAnswer}
                >
                  {t("submitClaimReward")}
                </Button>
              )}
            </div>
          ) : (
            <div className="content flex flex-col gap-6">
              <h3 className="page-heading px=10">
                {t("letsTakeASmallFunQuizToEarn")}
              </h3>

              <video
                ref={videoRef}
                autoPlay
                loop
                muted
                className="w-full rounded-xl"
                alt="dummy"
              >
                <source src={akshay} type="video/mp4"></source>
              </video>
              <div className="reward-box flex items-center justify-between">
                <span>{t("reward")}</span>
                <span>1 xCapx</span>
              </div>
              <Button onClick={handleBeginQuiz} className={"btns"} bRadius="md">
                {t("beginQuiz")}
              </Button>
              <Button
                onClick={handleSkipbtn}
                className={"btns"}
                bRadius="md"
                variant="outlined"
              >
                {t("skip")}
              </Button>
            </div>
          )}
        </div>
      </main>
      {loading && <Loader />}
    </>
  );
};

export default OnboardingQuiz;
