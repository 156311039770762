import React, { useCallback, useEffect, useState } from "react";
import InternalLayout2 from "../../layouts/internalLayout2";
import TokenChip from "../../components/chips/tokenChip/TokenChip";
import Button from "../../components/button/Button";
import { services } from "../../services";
import { toast } from "react-hot-toast";
import Loader from "../../components/loader/Loader";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const getCurrentTime = () => {
  return Math.ceil(new Date().getTime() / 1000);
};

const BonusChallenge = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [questData, setQuestData] = useState(null);

  const navigate = useNavigate();

  const getBonusChallenge = useCallback(async () => {
    setLoading(true);
    try {
      const response = await services.getBonusChallenge();
      setQuestData(response.data.result.questData);
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.result?.message) {
        toast.error(error.response.data.result.message);
      } else {
        toast.error(error.message);
      }
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    getBonusChallenge();
  }, [getBonusChallenge]);

  const handleRegisterChallange = async () => {
    setLoading(true);
    try {
      await services.registerBonusChallenge();
      await getBonusChallenge();
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.result?.message) {
        toast.error(error.response.data.result.message);
      } else {
        toast.error(error.message);
      }
    }
    setLoading(false);
  };

  return (
    <InternalLayout2
      handleBack={() => {
        navigate(-1);
      }}
    >
      {questData && (
        <div className="weekly-target-page flex flex-col grow gap-6">
          <h3 className="page-heading text-center">
            {t("invite5FriendsToDoubleRewards")}
          </h3>
          <TokenChip className="self-center" text="30 xCAPX" />

          <div className="stat-box">
            <div className="head flex items-center">
              <div className="basis-2/4 flex flex-col">
                <h5>{t("startDate")}</h5>
                <p>
                  {dayjs
                    .unix(questData.questInfo.startTime)
                    .format("DD MMM YYYY")}
                </p>
              </div>
              <div className="basis-2/4 flex flex-col">
                <h5>{t("endDate")}</h5>
                <p>
                  <p>
                    {" "}
                    {dayjs
                      .unix(questData.questInfo.endTime)
                      .format("DD MMM YYYY")}
                  </p>
                </p>
              </div>
            </div>
            {!questData.isUserRegistered ? (
              <div className="body flex flex-col gap-3">
                <p className="heading">{t("doYouAcceptTheChallenge")}</p>
                <div className="flex items-center gap-3">
                  <Button
                    onClick={handleRegisterChallange}
                    className="cha-btn"
                    bRadius="md"
                    disabled={questData.questInfo.startTime > getCurrentTime()}
                  >
                    {t("yes")}
                  </Button>
                  <Button
                    onClick={() => {
                      navigate("/challenges");
                    }}
                    className="cha-btn"
                    bRadius="md"
                    variant="outlined"
                    disabled={questData.questInfo.startTime > getCurrentTime()}
                  >
                    {t("no")}
                  </Button>
                </div>
              </div>
            ) : (
              <div className="body flex flex-col gap-3">
                <p className="heading">
                  {t("noOfPeopleInvitedUsingReferalLink")}
                </p>
                <div className="flex items-center gap-3">
                  {questData.questInfo.invitesData.invites
                    .toString()
                    .split()
                    .map((digit) => {
                      return (
                        <div className="number-box flex items-center justify-center">
                          {digit}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
          {questData.isUserRegistered && (
            <>
              <Button
                className="sb-btn"
                bRadius="md"
                variant="outlined"
                onClick={getBonusChallenge}
              >
                {t("refresh")}
              </Button>
            </>
          )}
        </div>
      )}
      {loading && <Loader />}
    </InternalLayout2>
  );
};

export default BonusChallenge;
