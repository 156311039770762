import React, { useEffect, useState } from "react";
import { Capx } from "../../../assets/icons";
import Input from "../../../components/Input/Input";
import Button from "../../../components/button/Button";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import useQueryParams from "../../../hooks/useQueryParams";
import { useFireBaseAuth } from "../../../hooks/useFirebaseAuth";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/loader/Loader";
import { auth } from "../../../firebase/firebase";
import { useDispatch } from "react-redux";
import { resetAuth } from "../../../store/slices/authSlice";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [isError, setisError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const { t } = useTranslation();
  const getQueryParam = useQueryParams();
  const { resetPassword, verifyEmailCall, signOutUser } = useFireBaseAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleShowPassword = (e) => {
    setShowPassword(e.target.checked);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const method = getQueryParam.get("mode");
        if (method === "verifyEmail") {
          await verifyEmailCall(getQueryParam.get("oobCode"));

          toast.success(t("yourEmailVerifiedSuccessfully"));
        }
      } catch (error) {
        console.log(error.message);
        setisError(error.message);
      }
      setLoading(false);
    })();
  }, []);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required("passwordIsRequired")
        .min(6, "minimum6LettersAreRequiredInPassword"),
      confirmPassword: Yup.string()
        .required("confirmPasswordIsRequired")
        .min(6, "minimum6LettersAreRequiredInPassword")
        .oneOf([Yup.ref("password"), null], "passwordsMustMatch"),
    }),
    validateOnChange: true,
    validateOnMount: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await resetPassword(getQueryParam.get("oobCode"), values.password);
        toast.success(t("yourPasswordHasResetSuccessfully"));
        navigate("/email/signin");
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      }
      setLoading(false);
    },
  });

  const handleBackToSignup = async () => {
    if (auth.currentUser) {
      try {
        await signOutUser();
        dispatch(resetAuth());
        navigate("/email/signin");
      } catch (error) {
        console.log(error);
      }
    } else {
      navigate("/email/signin");
    }
  };

  return (
    <>
      <div className="reset-password-page flex flex-col items-center">
        <div className=" wrappper min-h-screen flex flex-col items-center gap-10 md:gap-8 md:p-3 md:pt-32 pt-6 px-8">
          <Capx />
          {getQueryParam.get("mode") === "verifyEmail" && (
            <div className="flex flex-col gap-20 grow max-350">
              {loading ? (
                <div className="flex flex-col gap-3">
                  <h3 className="cus-h cus-h-2 text-center">
                    {t("verifyingOurEmail")}
                  </h3>
                  <p className="p-text">{t("yourEmailIsVerified")}</p>
                </div>
              ) : isError ? (
                <>
                  <div className="flex flex-col gap-3">
                    <h3 className="cus-h cus-h-2 text-center">{t("oops")}</h3>
                    <p className="p-text">{t("verificationLinkHasExpired")}</p>
                  </div>
                  <Button bRadius={"md"} onClick={handleBackToSignup}>
                    Back to Signin
                  </Button>
                </>
              ) : (
                <div className="flex flex-col gap-3">
                  <h3 className="cus-h cus-h-2 text-center">
                    {t("congratulation")}
                  </h3>
                  <p className="p-text">{t("yourEmailIsVerified")}</p>
                </div>
              )}
            </div>
          )}
          {getQueryParam.get("mode") === "resetPassword" && (
            <div className="flex flex-col gap-20 grow max-350">
              <div className="flex flex-col gap-3">
                <h3 className="cus-h cus-h-2 text-center">
                  {t("LetsSetAStrongNewPassword")}
                </h3>
                <p className="p-text">{t("FewSecondsAwayFromEarningSome")}</p>
              </div>

              <div className="flex flex-col gap-4 self-stretch grow">
                <div className="grow" />
                <form className="flex flex-col gap-3">
                  <div className="flex flex-col">
                    <Input
                      label={t("enterNewPassword")}
                      placeholder={t("enterAStrongPassword")}
                      type={showPassword ? "text" : "password"}
                      name="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.password && formik.errors.password}
                    />
                    {formik.touched.password && formik.errors.password && (
                      <p className="helper-text">{t(formik.errors.password)}</p>
                    )}
                  </div>
                  <div className="show-password flex items-center gap-1.5">
                    <input
                      type="checkbox"
                      checked={showPassword}
                      onChange={handleShowPassword}
                    />
                    <span>{t("showPassword")}</span>
                  </div>
                  <div className="flex flex-col">
                    <Input
                      label={t("confirmPassword")}
                      placeholder={t("reEnterPassword")}
                      type="password"
                      name="confirmPassword"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                      }
                    />
                    {formik.touched.confirmPassword &&
                      formik.errors.confirmPassword && (
                        <p className="helper-text">
                          {t(formik.errors.confirmPassword)}
                        </p>
                      )}
                  </div>
                </form>
                <div className="grow" />
                <div className="grow" />
                <Button
                  disabled={!formik.isValid || !formik.dirty}
                  className="sub-btn mb-10 "
                  onClick={formik.handleSubmit}
                  bRadius={"md"}
                >
                  {t("setNewPassword")}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default ResetPassword;
