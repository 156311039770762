import { Web3AuthNoModal } from "@web3auth/no-modal";
import { config } from "../config";
import { CHAIN_NAMESPACES } from "@web3auth/base";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";

const chainConfig = {
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  chainId: config.WEB3AUTH_CHAIN_ID,
  rpcTarget: config.WEB3AUTH_RPC_TARGET,
  displayName: config.WEB3AUTH_DISPLAY_NAME,
  blockExplorer: config.WEB3AUTH_BLOCK_EXPLORER,
  ticker: config.WEB3AUTH_TICKER,
  tickerName: config.WEB3AUTH_TICKER_NAME,
};

const location = window.location.href.replace(window.location.origin, "");

const web3auth = new Web3AuthNoModal({
  clientId: config.WEB3AUTH_CLIENT_ID,
  web3AuthNetwork: config.WEB3AUTH_NETWORK,
  chainConfig,
  sessionTime: 86400 * 7,
});
const privateKeyProvider = new EthereumPrivateKeyProvider({
  config: { chainConfig },
});

const openloginAdapter = new OpenloginAdapter({
  adapterSettings: {
    clientId: config.WEB3AUTH_CLIENT_ID, //Optional - Provide only if you haven't provided it in the Web3Auth Instantiation Code
    uxMode: "redirect",
    // network: config.WEB3AUTH_NETWORK,
    loginConfig: {
      jwt: {
        name: "webapp",
        verifier: config.WEB3AUTH_VERIFIER,
        typeOfLogin: "jwt",
        clientId: config.WEB3AUTH_CLIENT_ID,
      },
    },
  },
  privateKeyProvider,
});
web3auth.configureAdapter(openloginAdapter);

await web3auth.init();

window.history.pushState({}, "", location);

export default web3auth;
