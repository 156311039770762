import React, { useCallback, useEffect, useState } from "react";
import { Capx } from "../../../assets/icons";
import Button from "../../../components/button/Button";
import Input from "../../../components/Input/Input";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../../firebase/firebase";
import { setEmailVerified } from "../../../store/slices/authSlice";
import { services } from "../../../services";
import { useWeb3Auth } from "../../../context/web3Auth/Web3AuthProvider";
import { useFireBaseAuthV2 } from "../../../hooks/useFirebaseAuthV2";
import Loader from "../../../components/loader/Loader";
import { useFireBaseAuth } from "../../../hooks/useFirebaseAuth";
import toast from "react-hot-toast";
import { setUser } from "../../../store/slices/userSlice";

const EmailVerification = () => {
  const [loading, setLoading] = useState(false);
  const [isRefreshBtnDisabled, setIsRefreshBtnDisabled] = useState(false);
  const [setIntervalTime, setsetIntervalTime] = useState(10000);
  const [reloadQeruestCount, setReloadQeruestCount] = useState(0);
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const { getUserAccount, web3auth, login } = useWeb3Auth();

  const { signOutUserV2 } = useFireBaseAuthV2();
  const { verifyEmail } = useFireBaseAuth();

  const refreshInterval = async () => {
    try {
      await auth.currentUser.reload();
      if (auth.currentUser.emailVerified) {
        setLoading(true);
        const userObj = await services.getUser();
        if (userObj.data.result.userData) {
          dispatch(setUser({ ...userObj.data.result.userData }));
          dispatch(setEmailVerified(auth.currentUser.emailVerified));
        } else if (web3auth?.connected) {
          const account = await getUserAccount();
          const apiData = {
            wallet_address: account,
            biconomy_eoa_address: account,
            web3_provider: "web3auth",
          };
          const referral_code = localStorage.getItem("invite_code");
          if (referral_code) {
            apiData.data.referral_code = referral_code;
          }
          await auth.currentUser.getIdToken(true);
          const res = await services.createUser({
            data: apiData,
          });
          await signOutUserV2();
          dispatch(setEmailVerified(auth.currentUser.emailVerified));
        } else {
          await login();
        }

        setLoading(false);
      } else {
        if (reloadQeruestCount >= 5) {
          setsetIntervalTime((prev) => prev + 5000);
          setReloadQeruestCount(0);
        } else {
          setReloadQeruestCount((prev) => prev + 1);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleRefreshBtn = async () => {
    setLoading(true);
    setIsRefreshBtnDisabled(true);
    setTimeout(() => {
      setIsRefreshBtnDisabled(false);
    }, 5000);
    await refreshInterval();
    setLoading(false);
  };

  useEffect(() => {
    const interval = setInterval(refreshInterval, setIntervalTime);
    return () => {
      clearInterval(interval);
    };
  }, [reloadQeruestCount, auth]);

  const handleResendLink = async () => {
    setLoading(true);
    try {
      await verifyEmail();
      toast.success("Verification link sent successfully");
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="email-verification-page page flex flex-col items-center">
        <div className=" wrappper min-h-screen flex flex-col items-center gap-10 md:gap-8 md:p-3 md:pt-32 pt-6 px-8">
          <Capx />
          <div className="flex flex-col gap-20">
            <div className="flex flex-col gap-3">
              <h3 className="cus-h cus-h-2 text-center">
                {t("verify")} <br /> {t("yourEmailAddress")}
              </h3>
              <p className="p-text">{t("clickOnTheLinkSentToYourEmail")}</p>
            </div>
            <div className="flex flex-col gap-4 self-stretch">
              <Input
                label={t("yourEmail")}
                value={user?.email}
                success
                disabled
              />
              <div className="flex flex-col ">
                <Button
                  className="refresh-btn"
                  onClick={handleRefreshBtn}
                  bRadius={"md"}
                  disabled={isRefreshBtnDisabled}
                >
                  {t("refresh")}
                </Button>
                <span className="text-center text-[14px] mt-0.5">
                  {t("Didntreceivelink")}{" "}
                  <button
                    className="underline text-[#8787ff]"
                    onClick={handleResendLink}
                  >
                    {t("resend")}
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default EmailVerification;
