import React from "react";
import { Capx } from "../../../assets/icons";
import { quizdummy, profile_dummy } from "../../../assets/dummy";
import Button from "../../../components/button/Button";
import { setIsUserProfileSet } from "../../../store/slices/authSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const Congratulations = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { state: routerState } = useLocation();
  const hanldeBeginBtn = () => {
    dispatch(setIsUserProfileSet(true));
  };
  return (
    <main className="onboarding-quiz-page min-h-screen flex justify-center md:pt-20 pt-16">
      <div
        className="wrapper flex flex-col md:gap-8 gap-8 px-8 grow"
        style={{ maxWidth: "390px" }}
      >
        <Capx className="self-center" />
        <div className="congratulation flex flex-col items-center grow gap-16 pb-10">
          <div className="grow" />
          <div className="options flex flex-col gap-6">
            <img
              className="cong-img w-full rounded-full object-contain self-center"
              src={profile_dummy}
              alt="dummy"
            />
            <p className="cong-text text-center px-10">
              {t("woohoo!")}
              <br />
              {t("youJustEarned")}
              <br />
              {routerState.rewardString}
            </p>
          </div>
          <div className="grow" />
          <Button
            onClick={hanldeBeginBtn}
            className="btns self-stretch"
            bRadius={"md"}
          >
            {t("letsBegin")}
          </Button>
        </div>
      </div>
    </main>
  );
};

export default Congratulations;
