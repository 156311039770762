import React from "react";

const Chip = ({ color, text, className }) => {
  return (
    <div
      className={`capx-chip flex items-center gap-1 ${className}`}
      style={{
        borderColor: `rgba(${color}, 0.3)`,
        backgroundColor: `rgba(${color}, 0.05)`,
      }}
    >
      <div className="icon-wrapper relative">
        <div
          className="absolute circle-1"
          style={{ backgroundColor: `rgba(${color}, 0.3)` }}
        />
        <div
          className="absolute circle-2"
          style={{ backgroundColor: `rgba(${color}, 0.4)` }}
        />
        <div
          className="absolute circle-3"
          style={{ backgroundColor: `rgba(${color}, 1)` }}
        />
      </div>
      <span style={{ color: `rgba(${color}, 1)` }}>{text}</span>
    </div>
  );
};

export default Chip;
