import React from "react";
import InternalLayout2 from "../../layouts/internalLayout2";
import TokenChip from "../../components/chips/tokenChip/TokenChip";
import Button from "../../components/button/Button";

const Weeklytarget = () => {
  return (
    <InternalLayout2>
      <div className="weekly-target-page flex flex-col grow gap-6">
        <h3 className="page-heading text-center">
          Invite 7 people
          <br /> in a week
        </h3>
        <TokenChip className="self-center" text="7 xCAPX" />

        <div className="stat-box">
          <div className="head flex items-center">
            <div className="basis-2/4 flex flex-col">
              <h5>START DATE</h5>
              <p>24th July 2023</p>
            </div>
            <div className="basis-2/4 flex flex-col">
              <h5>END DATE</h5>
              <p>
                <p>24th July 2023</p>
              </p>
            </div>
          </div>
          <div className="body flex flex-col gap-3">
            <p className="heading">No. of People Invited</p>
            <div className="flex items-center gap-3">
              <div className="number-box flex items-center justify-center">
                -
              </div>
              <div className="number-box flex items-center justify-center">
                -
              </div>
            </div>
          </div>
        </div>
        <Button className="sb-btn" bRadius="md" variant="outlined">
          Verify
        </Button>
        <Button className="sb-btn" bRadius="md">
          Claim Reward
        </Button>
      </div>
    </InternalLayout2>
  );
};

export default Weeklytarget;
