import React, { useState } from "react";

const Tooltip = ({ children, message, className, secondMessage }) => {
  const [showSecondMessage, setShowSecondMessage] = useState(false);
  const handleClick = () => {
    if (secondMessage) {
      setShowSecondMessage(true);
      setTimeout(() => {
        setShowSecondMessage(false);
      }, 3000);
    }
  };
  return (
    <>
      <div
        className="group relative flex custom-tooltip"
        onClick={handleClick}
        onMouseLeave={() => {
          setShowSecondMessage(false);
        }}
      >
        {children}
        <span
          className={`absolute tooltip transition-all p-2 text-xs ${className}`}
        >
          {showSecondMessage ? secondMessage : message}
        </span>
      </div>
    </>
  );
};

export default Tooltip;
