import React, { useCallback, useEffect, useState } from "react";
import PageTitle from "../../components/pageTitle/PageTitle";
import Pagination from "../../components/pagination/Pagination";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import { services } from "../../services";
import Loader from "../../components/loader/Loader";
import Chip from "../../components/chips/chip/Chip";
import { Copy } from "../../assets/icons";
import { useSelector } from "react-redux";
import { config } from "../../config";

const Wallet = () => {
  const [walletData, setWalletData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);

  const { t } = useTranslation();
  const user = useSelector((state) => state.user);

  const fetchUserWallet = useCallback(async (activePage, length = 5) => {
    setLoading(true);
    try {
      const skip = (activePage - 1) * 5;
      const response = await services.fetchUserWallet(skip, length);
      setActivePage(activePage);
      setWalletData(response.data.result.walletDetails);
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.result?.message) {
        toast.error(error.response.data.result.message);
      } else {
        toast.error(error.message);
      }
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchUserWallet(activePage);
  }, [fetchUserWallet]);

  const paginationHandler = (pageNumber) => {
    fetchUserWallet(pageNumber);
  };

  const handleExplorerRedirect = (txHash) => {
    const url = `${config.WEB3AUTH_BLOCK_EXPLORER}tx/${txHash}`;
    window.open(url, "_blank");
  };

  return (
    <>
      <div className="wallet-page flex flex-col grow">
        <PageTitle title="Wallet" />
        {walletData && (
          <div className="flex flex-col gap-6 pt-6 grow">
            <div className="banner flex flex-col gap-4 ">
              <h4 className="heading">{t("totalEarnings")}</h4>
              <div className="flex items-end gap-3">
                <h3 className="token">{walletData.totalEarnings}</h3>
                <span className="token-name">xCAPX</span>
              </div>
              {user?.primary_wallet?.address && (
                <div className="address-box flex">
                  <Chip
                    text="Wallet"
                    className="address-chip gap-2"
                    color="90,204,0"
                  />
                  <div className="address flex items-center gap-2 ">
                    <span>
                      {user?.primary_wallet.address.slice(0, 6)}.....
                      {user?.primary_wallet.address.slice(
                        user?.primary_wallet.address.length - 6,
                        user?.primary_wallet.address.length
                      )}
                    </span>
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(
                          user?.primary_wallet.address
                        );
                        toast.success(t("walletAddressCopied"));
                      }}
                    >
                      <Copy />
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="grow flex flex-col">
              <div className="-mx-6 px-6 py-5 page-heading">
                {t("transactionHistory")}
              </div>
              <div className="table-wrapper -mx-6">
                <table>
                  <thead>
                    <tr>
                      <th className="text-left">
                        <span>S. NO.</span>
                      </th>
                      <th className="text-left">
                        <span>ACTIVITY</span>
                      </th>
                      <th className="text-left">
                        <span>TRANSACTION HASH</span>
                      </th>
                      <th className="text-center">
                        <span>AMOUNT</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {walletData &&
                      Object.values(walletData.transactions).length > 0 &&
                      Object.values(walletData.transactions).map(
                        (transaction, ind) => {
                          return (
                            <tr>
                              <td>{ind + 1 + (activePage - 1) * 5}</td>
                              <td>{transaction.title.slice(0, 10)}...</td>
                              <td
                                className="cursor-pointer"
                                onClick={() => {
                                  handleExplorerRedirect(transaction.txHash);
                                }}
                              >
                                {transaction.txHash.slice(0, 6)}...
                                {transaction.txHash.slice(
                                  transaction.txHash.length - 6,
                                  transaction.txHash.length
                                )}
                              </td>
                              <td className="text-center">
                                {transaction.txReward}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    {walletData &&
                      Object.values(walletData.transactions).length === 0 && (
                        <tr>
                          <td className="text-center" colSpan={4}>
                            {t("noTransactionAvailable")}{" "}
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
              <div className="grow" />
              {walletData.maxPages && (
                <div className="pagination-wrapper -mx-6 -mb-6 py-6">
                  <Pagination
                    activePage={activePage}
                    maxPages={walletData.maxPages}
                    handler={paginationHandler}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {loading && <Loader />}
    </>
  );
};

export default Wallet;
