import React from "react";
import ReactDOM from "react-dom/client";
import "./scss/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import en from "./assets/translations/en.json";
import hi from "./assets/translations/hi.json";
import { Web3AuthProvider } from "./context/web3Auth/Web3AuthProvider";

const selectedLang = localStorage.getItem("language");

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    hi: { translation: hi },
  },
  lng: selectedLang ? selectedLang : "hi",
  fallbackLng: "hi",
  interpolation: { escapeValue: false },
});

const urlParams = new URLSearchParams(window.location.search);
const myParam = urlParams.get("inviteCode");
if (myParam) {
  localStorage.setItem("invite_code", myParam);
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <Web3AuthProvider>
      <App />
    </Web3AuthProvider>
  </Provider>
  // </React.StrictMode>
);

reportWebVitals();
