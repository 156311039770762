import { useEffect, useState } from "react";

const GetEndTime = ({ endTime, handler }) => {
  const [formattedTime, setFormattedTime] = useState("");
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);

    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedHours} hr : ${formattedMinutes} min : ${formattedSeconds} sec`;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const currentTimestamp = Math.floor(Date.now() / 1000);
      if (currentTimestamp <= endTime) {
        const remainingSeconds = endTime - currentTimestamp;
        const formattedRemainingTime = formatTime(remainingSeconds);
        setFormattedTime(formattedRemainingTime);
      } else {
        clearInterval(timer);
        if (handler) handler();
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return <>{formattedTime}</>;
};

export default GetEndTime;
