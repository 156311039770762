import React, { useCallback, useEffect } from "react";
import { Coin } from "../../assets/icons";
import { MdOutlineLogout } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFireBaseAuth } from "../../hooks/useFirebaseAuth";
import { useDispatch, useSelector } from "react-redux";
import { useWeb3Auth } from "../../context/web3Auth/Web3AuthProvider";
import { resetAuth } from "../../store/slices/authSlice";
import { resetUser, setUser } from "../../store/slices/userSlice";
import { services } from "../../services";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { signOutUser } = useFireBaseAuth();
  const { web3auth } = useWeb3Auth();
  const user = useSelector((state) => state.user);

  const handleSignout = async () => {
    await signOutUser();
    dispatch(resetAuth());
    dispatch(resetUser());
    localStorage.removeItem("invite_code");
    if (web3auth && web3auth?.connected) {
      await web3auth.logout();
    }
  };

  const updateUser = useCallback(async () => {
    try {
      const user = await services.getUser();
      dispatch(setUser({ ...user.data.result.userData }));
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    updateUser();
  }, [updateUser]);

  return (
    <div className="header flex items-center px-6 justify-between">
      <button
        onClick={() => {
          navigate("/wallet");
        }}
        className="flex items-center gap-2"
      >
        <Coin />
        <span>{user.earned_rewards}</span>
      </button>
      <button
        onClick={() => {
          navigate("/profile");
        }}
        className="flex items-center gap-2"
      >
        <div className="profile-img capitalize overflow-hidden">
          {user.image_url ? (
            <img
              src={user.image_url}
              alt={user.username}
              className="w-full h-full object-cover"
            />
          ) : (
            user.name[0]
          )}
        </div>
        <span>
          {user.username.length <= 10
            ? user.username
            : `${user.username.slice(0, 10)}...`}
        </span>
      </button>
      <button onClick={handleSignout} className="flex items-center gap-2">
        <span>{t("signOut")}</span>
        <MdOutlineLogout />
      </button>
    </div>
  );
};

export default Header;
