import React from "react";
import {
  celphone,
  mail,
  onboarding_panda,
  whatsapp,
} from "../../../assets/png";
import { Capx } from "../../../assets/icons";
import Button from "../../../components/button/Button";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Signin = () => {
  const mediumScreen = useMediaQuery("md");
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="signup-page flex flex-col items-center">
      {mediumScreen && (
        <img className="w-full" src={onboarding_panda} alt="panda" />
      )}

      <div className=" wrappper flex flex-col items-center gap-6 md:gap-8 md:p-3 md:pt-32 pt-6">
        {" "}
        <Capx />
        <div className="flex flex-col self-stretch gap-5">
          <Button
            onClick={() => {
              navigate("/whatsapp/signin");
            }}
            bRadius={"md"}
            className="flex items-center justify-center gap-2"
          >
            <img src={whatsapp} alt="mail" />
            <span>{t("loginUsingWhatsapp")}</span>
          </Button>
          {/* <Button
            onClick={() => {
              navigate("/number/signin");
            }}
            bRadius={"md"}
            className="flex items-center justify-center gap-2"
          >
            <img src={celphone} alt="mail" />
            <span>{t("loginUsingPhoneNo")}</span>
          </Button> */}
          <Button
            onClick={() => {
              navigate("/email/signin");
            }}
            bRadius={"md"}
            className="flex items-center justify-center gap-2"
          >
            <img src={mail} alt="mail" />
            <span>{t("loginUsingEmail")}</span>
          </Button>
        </div>
        <p className="bottom-text">
          {t("WantToCreateANewAccount")}{" "}
          <button
            onClick={() => {
              navigate("/signup");
            }}
          >
            {t("signUp")}
          </button>
        </p>
      </div>
    </div>
  );
};

export default Signin;
