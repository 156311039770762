import React from "react";
import { Capx } from "../../assets/icons";

const PageTitle = ({ title }) => {
  return (
    <div className="page-title flex items-center justify-between -mx-6 px-6 pb-6">
      <h3>{title}</h3>
      <Capx />
    </div>
  );
};

export default PageTitle;
