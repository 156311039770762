import React from "react";
import {
  celphone,
  mail,
  onboarding_panda,
  whatsapp,
} from "../../../assets/png";
import { Capx } from "../../../assets/icons";
import Button from "../../../components/button/Button";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Signup = () => {
  const { t } = useTranslation();
  const mediumScreen = useMediaQuery("md");
  const navigate = useNavigate();
  return (
    <div className="signup-page flex flex-col items-center">
      {mediumScreen && (
        <img className="w-full" src={onboarding_panda} alt="panda" />
      )}

      <div className=" wrappper flex flex-col items-center gap-6 md:gap-8 md:p-3 md:pt-32 pt-6">
        <Capx />
        <div className="flex flex-col self-stretch gap-5">
          <Button
            onClick={() => {
              navigate("/whatsapp/signup");
            }}
            bRadius={"md"}
            className="flex items-center justify-center gap-2"
          >
            <img src={whatsapp} alt="mail" />
            <span>{t("signupUsingWhatsapp")}</span>
          </Button>
          {/* <Button
            onClick={() => {
              navigate("/number/signup");
            }}
            bRadius={"md"}
            className="flex items-center justify-center gap-2"
          >
            <img src={celphone} alt="mail" />
            <span>{t("signupUsingPhoneNo")}</span>
          </Button> */}
          <Button
            onClick={() => {
              navigate("/email/signup");
            }}
            bRadius={"md"}
            className="flex items-center justify-center gap-2"
          >
            <img src={mail} alt="mail" />
            <span>{t("signupUsingEmail")}</span>
          </Button>
        </div>
        <p className="bottom-text">
          {t("alreadyHaveAnAccount")}{" "}
          <button
            onClick={() => {
              navigate("/signin");
            }}
          >
            {t("logIn")}
          </button>
        </p>
      </div>
    </div>
  );
};

export default Signup;
