import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useState } from "react";
import { storage } from "../firebase/firebase";
import { logEvent } from "firebase/analytics";
import { auth, analytics } from "../firebase/firebase";

export const useFirebaseImageUpload = () => {
  const uploadImageToCloud = async (path, image) => {
    const storageRef = ref(storage, path);
    const uploadTask = await uploadBytesResumable(storageRef, image);
    const downloadURL = await getDownloadURL(uploadTask.ref);

    return downloadURL;
  };

  return { uploadImageToCloud };
};
