import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  generated_invite_code: "",
  cover_image_url: "",
  registered_on: null,
  balances: null,
  rank: 0,
  invites: 0,
  socials: null,
  quests_registered: 0,
  docVersion: null,
  email: "",
  completed_quests: null,
  level: 0,
  age: 0,
  docType: "",
  image_url: "",
  inviter_id: "",
  orgs_registered: 0,
  redemption_wallet: null,
  claimed_quests: null,
  unclaimed_balances: null,
  name: "",
  earned_rewards: 0,
  quests_completed: 0,
  join_tag: 0,
  registered_quests: null,
  username: "",
  inviter_code: "",
  primary_wallet: null,
  wallets: null,
  last_domain_update: 0,
  traction_invite_code: "",
  traction_invites: 0,
  traction_total_rewards: 0,
  phone: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.generated_invite_code = action.payload.generated_invite_code;
      state.cover_image_url = action.payload.cover_image_url;
      state.registered_on = action.payload.registered_on;
      state.balances = action.payload.balances;
      state.rank = action.payload.rank;
      state.age = action.payload.age;
      state.invites = action.payload.invites;
      state.socials = action.payload.socials;
      state.quests_registered = action.payload.quests_registered;
      state.docVersion = action.payload.docVersion;
      state.email = action.payload.email;
      state.completed_quests = action.payload.completed_quests;
      state.level = action.payload.level;
      state.docType = action.payload.docType;
      state.image_url = action.payload.image_url;
      state.inviter_id = action.payload.inviter_id;
      state.orgs_registered = action.payload.orgs_registered;
      state.redemption_wallet = action.payload.redemption_wallet;
      state.claimed_quests = action.payload.claimed_quests;
      state.unclaimed_balances = action.payload.unclaimed_balances;
      state.name = action.payload.name;
      state.earned_rewards = action.payload.earned_rewards;
      state.quests_completed = action.payload.quests_completed;
      state.join_tag = action.payload.join_tag;
      state.registered_quests = action.payload.registered_quests;
      state.username = action.payload.username;
      state.inviter_code = action.payload.inviter_code;
      state.primary_wallet = action.payload.primary_wallet;
      state.wallets = action.payload.wallets;
      state.last_domain_update = action.payload.last_domain_update;
      state.traction_invite_code = action.payload.traction_invite_code;
      state.traction_invites = action.payload.traction_invites;
      state.traction_total_rewards = action.payload.traction_total_rewards;
      state.phone = action.payload.phone;
    },
    resetUser: (state, action) => {
      state.generated_invite_code = "";
      state.cover_image_url = "";
      state.registered_on = null;
      state.balances = null;
      state.rank = 0;
      state.invites = 0;
      state.socials = null;
      state.quests_registered = 0;
      state.docVersion = null;
      state.email = "";
      state.completed_quests = null;
      state.level = 0;
      state.docType = "";
      state.image_url = "";
      state.inviter_id = "";
      state.orgs_registered = 0;
      state.redemption_wallet = null;
      state.claimed_quests = null;
      state.unclaimed_balances = null;
      state.name = "";
      state.earned_rewards = 0;
      state.quests_completed = 0;
      state.join_tag = 0;
      state.registered_quests = null;
      state.username = "";
      state.inviter_code = "";
      state.primary_wallet = null;
      state.wallets = null;
      state.last_domain_update = 0;
      state.traction_invite_code = "";
      state.traction_invites = 0;
      state.traction_total_rewards = 0;
      state.phone = "";
    },
  },
});

export const { setUser, resetUser } = userSlice.actions;

export default userSlice.reducer;
