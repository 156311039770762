import InternalLayout from "../layouts/internalLayout";
import OnboardingLayout from "../layouts/onboardingLayout/Index";
import Home from "../pages/Home";
import Arcade from "../pages/arcade";
import Congratulations from "../pages/auth/congratulations";
import CreateUser from "../pages/auth/createUser";
import EmailLogin from "../pages/auth/emailLogin";
import EmailVerification from "../pages/auth/emailVerification";
import ForgotPassword from "../pages/auth/forgotPassword";
import NumberLogin from "../pages/auth/numberLogin";
import Onboarding from "../pages/auth/onboarding";
import OnboardingQuiz from "../pages/auth/onboardingQuiz";
import ResetPassword from "../pages/auth/resetPassword";
import Signin from "../pages/auth/signin";
import Signup from "../pages/auth/signup";
import VerifyOTP from "../pages/auth/verifyOTP";
import WhatsappLogin from "../pages/auth/whatsappLogin";
import BonusChallenge from "../pages/bonusChallenge";
import Challenges from "../pages/challenges";
import Mine from "../pages/mine";
import Profile from "../pages/profile";
import Wallet from "../pages/wallet";
import Weeklytarget from "../pages/weeklyTarget/Weeklytarget";

const { Navigate } = require("react-router-dom");

const Redirect = ({ to }) => {
  return <Navigate to={to} />;
};

export const publicRoutes = [
  {
    path: "/",
    element: <OnboardingLayout />,
    children: [
      {
        index: true,
        element: <Redirect to="/onboarding" />,
      },
      {
        path: "onboarding",
        element: <Onboarding />,
      },
      {
        path: "signup",
        element: <Signup />,
      },
      {
        path: "signin",
        element: <Signin />,
      },
      {
        path: "whatsapp/:method",
        element: <WhatsappLogin />,
      },

      // {
      //   path: "number/:method",
      //   element: <NumberLogin />,
      // },
      {
        path: "email/:method",
        element: <EmailLogin />,
      },
      {
        path: "verify-otp",
        element: <VerifyOTP />,
      },

      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "authentication",
        element: <ResetPassword />,
      },
    ],
  },

  {
    path: "*",
    element: <Redirect to="/onboarding" />,
  },
];

export const privateRoutes = [
  {
    path: "/",
    element: <InternalLayout />,
    children: [
      {
        index: true,
        element: <Redirect to="/home" />,
      },
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "challenges",
        element: <Challenges />,
      },
      {
        path: "mine",
        element: <Mine />,
      },
      {
        path: "wallet",
        element: <Wallet />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "arcade",
        element: <Arcade />,
      },
    ],
  },
  {
    path: "/weekly-target",
    element: <Weeklytarget />,
  },

  {
    path: "/bonus-challenge",
    element: <BonusChallenge />,
  },
  {
    path: "*",
    element: <Redirect to="/home" />,
  },
];

export const semiPrivateRoutes = [
  {
    path: "/create-user",
    element: <CreateUser />,
  },
  {
    path: "/onboarding-quiz",
    element: <OnboardingQuiz />,
  },
  {
    path: "/congratulations",
    element: <Congratulations />,
  },
  {
    path: "*",
    element: <Redirect to="/onboarding-quiz" />,
  },
];

export const emailVerificationRoutes = [
  {
    path: "/",
    element: <OnboardingLayout />,
    children: [
      {
        index: true,
        element: <Redirect to="/email-verification" />,
      },

      {
        path: "email-verification",
        element: <EmailVerification />,
      },
      {
        path: "authentication",
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: "*",
    element: <Redirect to="/email-verification" />,
  },
];
