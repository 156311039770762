import { useEffect, useState } from "react";
import {
  emailVerificationRoutes,
  privateRoutes,
  publicRoutes,
  semiPrivateRoutes,
} from "./contants";
import { useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
import { auth } from "../firebase/firebase";

const Routes = () => {
  const [routes, setRoutes] = useState([]);
  const { isAuthReady, isLoggedIn, emailVerified, isUserProfileSet, user } =
    useSelector((state) => state.auth);

  useEffect(() => {
    if (isAuthReady) {
      if (isLoggedIn) {
        if (emailVerified || user.phoneNumber) {
          if (isUserProfileSet) {
            setRoutes(privateRoutes);
          } else {
            setRoutes(semiPrivateRoutes);
          }
        } else {
          setRoutes(emailVerificationRoutes);
        }
      } else {
        setRoutes(publicRoutes);
      }
    }
  }, [isAuthReady, isLoggedIn, emailVerified, isUserProfileSet]);

  return useRoutes(routes);
};

export default Routes;
