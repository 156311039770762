import { useEffect } from "react";
import { auth } from "../firebase/firebase";
import { onAuthStateChanged, onIdTokenChanged } from "firebase/auth";
import { services } from "../services";
import { useDispatch } from "react-redux";
import { resetAuth, setAuth } from "../store/slices/authSlice";
import { toast } from "react-hot-toast";
import { useWeb3Auth } from "../context/web3Auth/Web3AuthProvider";
import { resetUser, setUser } from "../store/slices/userSlice";
import { useFireBaseAuthV2 } from "./useFirebaseAuthV2";

export const useFireBaseAuthstate = () => {
  const dispatch = useDispatch();
  const { signOutUserV2 } = useFireBaseAuthV2();
  const { web3auth, login, logout, getUserAccount } = useWeb3Auth();

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userObj = await services.getUser();
          if (
            !userObj.data.result.userData &&
            (user.emailVerified || user.phoneNumber)
          ) {
            if (!web3auth?.connected) {
              await login();
            }
            const walletAddress = await getUserAccount();
            const apiData = {
              data: {
                wallet_address: walletAddress,
                biconomy_eoa_address: walletAddress,
                web3_provider: "web3auth",
              },
            };
            const referral_code = localStorage.getItem("invite_code");
            if (referral_code) {
              apiData.data.referral_code = referral_code;
              localStorage.removeItem("invite_code");
            }
            if (user.phoneNumber) {
              apiData.data.phone = user.phoneNumber;
            }
            await user.getIdToken(true);
            await services.createUser(apiData);
            await signOutUserV2();
          } else {
            dispatch(setUser({ ...userObj.data.result.userData }));
          }
          let isUserProfileSet;
          if (
            userObj.data.result.userData &&
            (userObj.data.result.userData.age !== 0 ||
              userObj.data.result.userData.docVersion === 2)
          ) {
            isUserProfileSet = true;
          } else {
            isUserProfileSet = false;
          }
          dispatch(
            setAuth({
              isAuthReady: true,
              isLoggedIn: true,
              emailVerified: user.emailVerified,
              user: user,
              isUserProfileSet,
            })
          );
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log("not Logged in");
        if (web3auth?.connected) {
          await logout();
        }
        dispatch(
          setAuth({
            isAuthReady: true,
            isLoggedIn: false,
            emailVerified: false,
            user: null,
            isUserProfileSet: false,
          })
        );
        dispatch(resetAuth());
        dispatch(resetUser());
      }
    });

    onIdTokenChanged(auth, async (user) => {
      if (user) {
        if (user.stsTokenManager.expirationTime <= Date.now()) {
          await auth.currentUser.getIdToken(true);
        }
      }
    });

    return () => {
      unsub();
    };
  }, []);
};
