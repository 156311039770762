import React, { useCallback, useEffect, useState } from "react";
import PageTitle from "../../components/pageTitle/PageTitle";
import Button from "../../components/button/Button";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import { services } from "../../services";
import Loader from "../../components/loader/Loader";
import GetEndTime from "../../components/getEndTime/GetEndTime";
import CongratulationModal from "../../components/congratulationModal/CongratulationModal";
import { setUser } from "../../store/slices/userSlice";
import { useDispatch } from "react-redux";

const getCurrentTime = () => {
  return Math.ceil(new Date().getTime() / 1000);
};

const Mine = () => {
  const [loading, setLoading] = useState(false);
  const [mineData, setMineData] = useState(null);
  const [activeMineBtn, setActiveMineBtn] = useState(false);
  const [rewardStringForModal, setrewardStringForModal] = useState("");
  const [isCongratulationModalOpen, setIsCongratulationModalOpen] =
    useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getMineQuest = useCallback(async () => {
    setLoading(true);
    try {
      const response = await services.getMineQuest();
      setMineData(response.data.result.questData);
      setActiveMineBtn(
        response.data.result.questData.nextActivationTime < getCurrentTime()
      );
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.result?.message) {
        toast.error(error.response.data.result.message);
      } else {
        toast.error(error.message);
      }
    }
    setLoading(false);
  }, []);

  const handleMining = async () => {
    setLoading(true);
    try {
      if (!activeMineBtn) {
        throw new Error("Please wait till given time");
      }
      const response = await services.completeMineQuest();
      await getMineQuest();
      setrewardStringForModal(response.data.result.reward);
      setIsCongratulationModalOpen(true);
      const user = await services.getUser();
      dispatch(setUser({ ...user.data.result.userData }));
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.result?.message) {
        toast.error(error.response.data.result.message);
      } else {
        toast.error(error.message);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getMineQuest();
  }, [getMineQuest]);

  const handleModalClose = async () => {
    setIsCongratulationModalOpen(false);
  };

  return (
    <>
      <div className="mine-page flex flex-col">
        <PageTitle title="Mine" />
        {mineData && (
          <div className="flex flex-col gap-6 pt-6">
            <p className="discription">
              {t("minePageBannerText1")}
              <br /> <br /> {t("minePageBannerText2")}
            </p>
            <Button bRadius={"md"} className="sub-btn" onClick={handleMining}>
              {!activeMineBtn ? (
                <>
                  {mineData && (
                    <GetEndTime
                      endTime={mineData.nextActivationTime}
                      handler={() => {
                        setActiveMineBtn((prev) => !prev);
                      }}
                    />
                  )}
                </>
              ) : (
                "Mine"
              )}
            </Button>
            {!activeMineBtn && (
              <p className="helper-text">{t("capxMineResetsEvery24Hours")}</p>
            )}
          </div>
        )}
      </div>
      {loading && <Loader />}
      <CongratulationModal
        open={isCongratulationModalOpen}
        onClose={handleModalClose}
        btnText={t("backToCapxMine")}
        btnVariant="outlined"
        helperText={t("capxMineResetsEvery24Hours")}
        text={
          <>
            {t("youJustEarned")} <br /> {rewardStringForModal}
          </>
        }
        handleSubmit={handleModalClose}
        showFooter={true}
      />
    </>
  );
};

export default Mine;
